import HttpClient from "./../services/httpClient";

export const logReportService ={
    add
}

function add(reportName, user) {
    const data = {
        Report: reportName,
        AppName: 'Dashboard Report',
        UserID: user.id,
        UserName: user.name,
        HostName: window.location.hostname,
        Login: ''
    }

    // postData(`/reports/log`, data).then((response) => {
    //     if (response.status === 200) {

    //     }
    // })
}

function postData (url, data) {
    return new Promise((resolve, eject) => {
        HttpClient.post(url, data)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                eject(error);
            })
    });
}



