import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useDispatch, connect} from 'react-redux';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Button from './../../components/button/Button';
import * as ActionTypes from '../../store/actions';
import {userLogin} from '../../store/actions/authAction';

const Login = ({onUserLogin}) => {
    const dispatch = useDispatch();
    const [isAuthLoading, setAuthLoading] = useState(false);
    const history = useHistory();
    const [t] = useTranslation();

    const login = (username, password) => {
        setAuthLoading(true);
        dispatch(userLogin({username, password}))
                 .then((response) => {
                toast.success('Login is succeed!');
                setAuthLoading(false);
                //onUserLogin(token);
                history.push('/');
            })
            .catch((error) => {
                setAuthLoading(false);
                toast.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    'Failed'
                );
            });
    };

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .min(5, 'Too Short!')
                .max(20, 'Too Long!')
                .required('Required'),
            password: Yup.string()
                .min(5, 'Must be 5 characters or more')
                .max(30, 'Must be 30 characters or less')
                .required('Required')
        }),
        onSubmit: (values) => {
            login(values.username, values.password);
        }
    });

    document.getElementById('root').classList = 'hold-transition login-page';

    return (
        <div className="login-box">
            <div className="text-center pb-4">
                <img
                    src="img/logo.png"
                    alt="LVC Logo"
                    className="brand-image img-circle elevation-3"
                    style={{opacity: '.8'}}
                />
            </div>

            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <Link to="/" className="h1">

                        <b>LVC KCS</b>
                        <span className="pl-1"></span>
                    </Link>
                </div>
                <div className="card-body">
                    <p className="login-box-msg">{t('login.label.signIn')}</p>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Username"
                                    {...formik.getFieldProps('username')}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            {formik.touched.username && formik.errors.username ? (
                                <div>{formik.errors.username}</div>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <div className="input-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    {...formik.getFieldProps('password')}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            {formik.touched.password &&
                            formik.errors.password ? (
                                <div>{formik.errors.password}</div>
                            ) : null}
                        </div>

                        <div className="row">
                            <div className="col-8">
                                <div className="icheck-primary">
                                    <input type="checkbox" id="remember" />
                                    <label htmlFor="remember">
                                        {t('login.label.rememberMe')}
                                    </label>
                                </div>
                            </div>
                            <div className="col-4">
                                <Button
                                    block
                                    type="submit"
                                    isLoading={isAuthLoading}
                                >
                                    {t('login.button.signIn.label')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({type: ActionTypes.LOGIN_USER, token})
});

export default connect(null, mapDispatchToProps)(Login);
