import React, {useEffect, useState} from 'react';
import { Dropdown } from 'semantic-ui-react'
import {finishLoading, startLoading} from "./../../store/actions/uiAction";
import HttpClient from "./../../services/httpClient";
import {useDispatch} from "react-redux";
import {setIdAgencia} from "./../../store/actions/dataAction";


const ComboAgencias = () => {
    const dispatch = useDispatch();
    const [AgenciasData, setAgenciasData] = useState([]);
    const [Agencia, setAgencia] = useState(0);
    
    
    
    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const getOptionData = (url) => {
        return new Promise((resolve, eject) => {
            getDataReport(url).then((response) => {
                if (response.status === 200) {
                    let obj = response.data;
                    let res = [];
                    for(let i in obj) {
                        let item = {key: obj[i].id, text: obj[i].name, value: obj[i].id};
                        res.push(item)
                    }

                    resolve(res);
                }
            })
        })
    }

    const getAgenciasData = () => {
        getOptionData('/catalog/agencias')
            .then((response) => {
                setAgenciasData(response);
            })
    }


    const handleAgenciaChange = (e, {value}) => {
        setAgencia(value);     
        dispatch(setIdAgencia(value));
       
    } 

    useEffect(() => {
        getAgenciasData();
    }, []);

    return (
        <>
            <div className="row col-md-12">
                    <div className="form-group col-md-6">
                        <label>Agencies:</label>
                        <Dropdown className="form-control" placeholder='ALL AGENCIES' selection clearable search 
                                  options={AgenciasData}
                                  value={Agencia}
                                  onChange={handleAgenciaChange}
                                 
                        />
                    </div>
            </div>
        </>
    );
};

export default ComboAgencias;
