import * as ActionTypes from '../actions';

const initialState = {
    isLoggedIn: false,
    token: null,
    currentUser: {
        username: '',
        id: null,
        name: '',
        email: '',
        picture: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_USER:
            sessionStorage.setItem('token_security', action.data.token);
            return executeLoginSuccess(state, action);

        case ActionTypes.LOGOUT_USER:
            sessionStorage.removeItem('token_security');
            return executeLogoutSuccess(state, action);

        case ActionTypes.LOAD_USER:
            return {
                ...state,
                currentUser: action.currentUser
            };

        default:
            return {...state};
    }
};

export default reducer;

const executeLoginSuccess = (state, action) => {
    return {
        ...state,
        isLoggedIn: true,
        token: action.data.token,
        currentUser: {
            username: action.data.username,
            id: action.data.id,
            name: action.data.name,
            email: '',
            picture: null
        }
    };
}

const executeLogoutSuccess = (state, action) => {
    return {
        ...state,
        isLoggedIn: false,
        token: null,
        currentUser: {
            username: '',
            id: null,
            name: '',
            email: '',
            picture: null
        }
    };
}


