import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SalesRoomDropdown = () => {
    const dropdownRef = useRef(null);
    const { t } = useTranslation();

    const [dropdownState, updateDropdownState] = useState({
        isDropdownOpen: false
    });

    const toggleDropdown = () => {
        updateDropdownState({ isDropdownOpen: !dropdownState.isDropdownOpen });
    };

    let className = 'nav-item';
    if (dropdownState.isDropdownOpen) {
        className = 'nav-item menu-is-opening menu-open';
    } else {
        className = 'nav-item';
    }

    return (
        <li ref={dropdownRef} className={className}>
            <a
                className="nav-link"
                onClick={toggleDropdown}
            >
                <i className="nav-icon fas fa-copy" />
                <p>
                    {t('menusidebar.label.sales-room-report')}
                    <i className="fas fa-angle-left right" />
                </p>
            </a>
            <ul className="nav nav-treeview">
                
                {/*<li className="nav-item">
                    <Link to="/adp-due-date-report" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>{t('menusidebar.label.adp-due-date-report')}</p>
                    </Link>
                </li>*/}
              
                {/*<li className="nav-item">*/}
                {/*    <Link to="cxc-report" className="nav-link">*/}
                {/*        <i className="far fa-circle nav-icon" />*/}
                {/*        <p>{t('menusidebar.label.cxc-report')}</p>*/}
                {/*    </Link>*/}
                {/*</li>*/}
                {/*<li className="nav-item">
                    <Link to="/gift-location-report" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>{t('menusidebar.label.gift-location-report')}</p>
                    </Link>
                </li>*/}
                {/*<li className="nav-item">
                    <Link to="gift-report" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>{t('menusidebar.label.gift-report')}</p>
                    </Link>
                </li>*/}
                <li className="nav-item">
                    <Link to="pre-manifest" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>{t('menusidebar.label.pre-manifest')}</p>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="manifest-report" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>{t('menusidebar.label.manifest-report')}</p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="sales-report" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>{t('menusidebar.label.sales-report')}</p>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/contract-analyst" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>{t('menusidebar.label.contract-analyst')}</p>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/sales-room-stats" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>{t('menusidebar.label.sales-room-stats')}</p>
                    </Link>
                </li>
                {/*<li className="nav-item">
                    <Link to="greeters-report" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>{t('menusidebar.label.greeters-report')}</p>
                    </Link>
                </li>*/}
            </ul>
        </li>
    );
};

export default SalesRoomDropdown;
