import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const GeneralPayrollDropdown = () => {
    const dropdownRef = useRef(null);
    const {t} = useTranslation();

    const [dropdownState, updateDropdownState] = useState({
        isDropdownOpen: false
    });

    const toggleDropdown = () => {
        updateDropdownState({isDropdownOpen: !dropdownState.isDropdownOpen});
    };

    let className = 'nav-item';
    if (dropdownState.isDropdownOpen) {
        className = 'nav-item menu-is-opening menu-open';
    } else {
        className = 'nav-item';
    }

    return (
        <li ref={dropdownRef} className={className}>
            <a
                className="nav-link"
                onClick={toggleDropdown}
            >
                <i className="nav-icon fas fa-copy" />
                <p>
                    {t('menusidebar.label.general-payroll')}
                    <i className="fas fa-angle-left right" />
                </p>
            </a>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                    <Link to="/personal" className="nav-link">
                        <i className="fa fa-users nav-icon" />
                        <p>{t('menusidebar.label.personal')}</p>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/general-arrival" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>{t('menusidebar.label.general-arrival')}</p>
                    </Link>
                </li>
               {/* <li className="nav-item">
                    <Link to="print-log" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>{t('menusidebar.label.print-log')}</p>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/active-membership" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>{t('menusidebar.label.active-membership-menu')}</p>
                    </Link>
                </li>*/}
            </ul>
        </li>
    );
};

export default GeneralPayrollDropdown;
