import * as actionTypes from './../actions';
import axios from 'axios';

const instance = axios.create();
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export const userLogin = (user) => {
    return async (dispatch) => {
        try {
            const response = await instance.post("/user/authenticate", user);
            dispatch(userLoginSuccess(response.data));
        } catch (error) {
            throw Error(error);
        }
    }
};

export const getCurrentUser = () => {
    return async (dispatch) => {
        try {
            const response = await instance.get("/user");
            dispatch(userLoginSuccess(response.data));
        } catch(error) {
            throw Error(error);
        }
    }
}

const userLoginSuccess = (data) => {
    return {
        type: actionTypes.LOGIN_USER,
        data: data,
        authenticated: true
    }
}

export const userLogout = () => {
    return (dispatch) => {
        sessionStorage.removeItem('token_security');
        dispatch(userLogoutSuccess());
    }
}

const userLogoutSuccess = () => {
    return {
        type: actionTypes.CLOSE_SESSION,
        data: null,
        authenticated: false
    }
}