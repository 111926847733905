import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ColumnDirective, ColumnsDirective,
    GridComponent, Grid, Inject, Page, Sort, Filter, Resize,
    Toolbar, ExcelExport, PdfExport, ColumnChooser
} from '@syncfusion/ej2-react-grids';
import { Aggregate, ForeignKey, Group, getForeignData, AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import HttpClient from './../../../services/httpClient';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Dropdown, Select } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { useTranslation } from "react-i18next";
import { finishLoading, setTitle, startLoading } from "./../../../store/actions/uiAction";
import { logReportService } from "./../../../services/logReportService";
import ComboHotelSala from "./../../../components/combo-hotel-sala/combo-hotel-sala";
import { MultiSelectComponent, CheckBoxSelection, Inject as InjectDrop } from '@syncfusion/ej2-react-dropdowns';

const SalesRoomStats = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [rowData, setRowData] = useState([]);
    const user = useSelector(state => state.auth.currentUser);
    const { idSaleRoom, idHotel } = useSelector(state => state.user.param);
    const [puestosData, setPuestosData] = useState([]);
    const [NameSala, setNameSala] = useState([]);
    const [NameHotel, setNameHotel] = useState([]);
    const [NamePuesto, setNamePuesto] = useState([]);
    const toolbarOptions = ['ColumnChooser'];

    let grid = null;

    let mulObjPuestos = [];

    const options = [
        { key: 'excel', icon: 'edit', text: 'Export to excel', value: 'excel' },
        { key: 'pdf', icon: 'delete', text: 'Export to pdf', value: 'pdf' },
    ]

    const checkFields = { text: 'Name', value: 'Code' };

    const onChangeDateStart = (event, data) => setDateStart(data.value);
    const onChangeDateEnd = (event, data) => setDateEnd(data.value);

    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const handleViewButton = () => {
        getManifestReport();
    }

    const getData = (url, data) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url, data)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const getOptionData = (url) => {
        return new Promise((resolve, eject) => {
            getData(url).then((response) => {
                if (response.status === 200) {
                    let obj = response.data;
                    let res = [];
                    for (let i in obj) {
                        let item = { Code: obj[i].id, Name: obj[i].name };
                        res.push(item)
                    }

                    resolve(res);
                }
            })
        })

    }
    const getPuestosData = () => {
        getOptionData('/catalog/puestos')
            .then((response) => {
                setPuestosData(response);
            })
    }

    const getManifestReport = () => {

        const date1 = dateStart.toISOString().slice(0, 10).replace(/-/g, '');
        const date2 = dateEnd.toISOString().slice(0, 10).replace(/-/g, '');

        const puestos = (mulObjPuestos != null && mulObjPuestos.value.length > 0) ? mulObjPuestos.value.map(String).toString() : "0";

        getDataReport(`/catalog/getNameSalavtaId/${idSaleRoom}`).then((response) => {
            if (response.status === 200) {
                setNameSala(response.data);
            }
        })

        getDataReport(`/catalog/getNameHotelId/${idHotel}`).then((response) => {
            if (response.status === 200) {
                setNameHotel(response.data);
            }
        })

        getDataReport(`/catalog/getNamePuestoId/${puestos}`).then((response) => {
            if (response.status === 200) {
                setNamePuesto(response.data);
            }
        })
        getDataReport(`/reports/sales-room-statics/${date1}/${date2}/${idSaleRoom}/${idHotel}/${puestos}`).then((response) => {
            if (response.status === 200) {
                setRowData(response.data);
                logReportService.add('Sales room statistic', user);
            }
        })
    }

    // Reporte
    const handleButtonOption = (opt) => {
        if (grid && opt.target.innerText === 'Export to excel') {
            export2Excel();
        } else {
            if (grid && opt.target.innerText === 'Export to pdf') {
                grid.pdfExport();
            }
        }
    }

    const export2Excel = () => {
        const date1 = dateStart.toDateString();
        const date2 = dateEnd.toDateString();
        const excelExportProperties = {
            fileName: t('menusidebar.label.sales-room-statistics') + ".xlsx",
            theme: {
                caption: { fontName: 'Calibri', fontColor: '#666666', fontSize: 13 },
                header: { fontName: 'Calibri', fontColor: '#000000', backColor: '#3F97BF', fontSize: 13 },
            },
            header: {
                headerRows: 7,
                rows: [
                    { cells: [{ colSpan: 4, value: "LVC KCS", style: { fontColor: '#000000', fontSize: 20, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: t('menusidebar.label.sales-room-statistics'), style: { fontColor: '#000000', fontSize: 16, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "SALES ROOM: " + NameSala[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "HOTELS: " + NameHotel[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "JOBS: " + NamePuesto[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: date1 + " - " + date2, style: { fontColor: '#000000', fontSize: 16, hAlign: 'Left', bold: true, } }] },

                ]
            }
        }
        grid.excelExport(excelExportProperties);
    }

    const handleExport2Excel = () => {
        if (grid) {
            export2Excel();
        }
    }

    const exportExcelEfficiencyFromAPI = () => {
        const base_url = process.env.REACT_APP_URL_API_PATH;
        const date1 = dateStart.toISOString().slice(0, 10).replace(/-/g, '');
        const date2 = dateEnd.toISOString().slice(0, 10).replace(/-/g, '');
        const puestos = (mulObjPuestos != null && mulObjPuestos.value.length > 0) ? mulObjPuestos.value.map(String).toString() : "0";

        var download_excel_url = `${base_url}/reports/sales-room-statics-xls/${date1}/${date2}/${idSaleRoom}/${idHotel}/${puestos}`;
        //console.log(download_excel_url);
        window.open(download_excel_url, '_parent');
    }


    const groupOptions = {
        columns: ['puesto'],
        showDropArea: false
    };

    const handleToolbarClick = (args) => {
        if (grid && args.item.id === 'grid_excelexport') {
            export2Excel();
        } else {
            if (grid && args.item.id === 'grid_pdfexport') {
                grid.pdfExport();
            }
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns();
        }
    }
    function footerSum(props) {
        return (<span>{props.Sum}</span>);

    }

    const footerArrivalSum = (props) => {
        return (<span>{props.Sum}</span>);
    }

    const footeAvailsSum = (props) => {
        return (<span>{props.Sum}</span>);
    }

    function footerAverage(props) {
        return (<span>{props.Average}</span>);
    }

    function custom(props) {
        return (<span>{props.Custom}</span>);
    }




    function customAggregateFnCl() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['cancelled'];
            totalSum2 = totalSum2 + gridData[i]['totalSales'];
        }

        let average = 0;
        totalSum2 == 0 ? average = totalSum2 : average = totalSum1 / totalSum2;
        // return the calculated value

        return average;

    }

    function customAggregateFnEff() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['totalVolume'];
            totalSum2 = totalSum2 + gridData[i]['sh'];
        }

        let eff = 0;

        totalSum2 == 0 ? eff = 0 : eff = totalSum1 / totalSum2;


        // return the calculated value

        return eff;

    }

    function customAggregateFnEffQs() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['volumenqs'];
            totalSum2 = totalSum2 + gridData[i]['qs'];
        }

        let eff = 0;

        totalSum2 == 0 ? eff = 0 : eff = totalSum1 / totalSum2;


        // return the calculated value

        return eff;

    }


  


    function customAggregateFnEffNQs() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['volnqs'];
            totalSum2 = totalSum2 + gridData[i]['nqs'];
        }

        let eff = 0;

        totalSum2 == 0 ? eff = 0 : eff = totalSum1 / totalSum2;


        // return the calculated value

        return eff;

    }

    function customAggregateFnEffCts() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['volumencts'];
            totalSum2 = totalSum2 + gridData[i]['ct'];
        }

        let eff = 0;

        totalSum2 == 0 ? eff = 0 : eff = totalSum1 / totalSum2;


        // return the calculated value

        return eff;

    }

    function customAggregateFnEffAnual() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['yearsalesamount'];
            totalSum2 = totalSum2 + gridData[i]['yearsh'];
        }

        let eff = 0;

        totalSum2 == 0 ? eff = 0 : eff = totalSum1 / totalSum2;


        // return the calculated value

        return eff;

    }


    function customAggregateFnClo() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['totalSales'];
            totalSum2 = totalSum2 + gridData[i]['sh'];
        }

        let closingPercent = 0;

        totalSum2 == 0 ? closingPercent = 0 : closingPercent = totalSum1 / totalSum2;

        // return the calculated value

        return closingPercent;

    }

    function customAggregateFnCloqs() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['salesqs'];
            totalSum2 = totalSum2 + gridData[i]['qs'];
        }

        let closingPercent = 0;

        totalSum2 == 0 ? closingPercent = 0 : closingPercent = totalSum1 / totalSum2;

        // return the calculated value

        return closingPercent;

    }

    function customAggregateFnClonqs() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['salesnqs'];
            totalSum2 = totalSum2 + gridData[i]['nqs'];
        }

        let closingPercent = 0;

        totalSum2 == 0 ? closingPercent = 0 : closingPercent = totalSum1 / totalSum2;

        // return the calculated value

        return closingPercent;

    }
    function customAggregateFnClocts() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['salesqs'];
            totalSum2 = totalSum2 + gridData[i]['qs'];
        }

        let closingPercent = 0;

        totalSum2 == 0 ? closingPercent = 0 : closingPercent = totalSum1 / totalSum2;

        // return the calculated value

        return closingPercent;

    }

    function customAggregateFnCloAnual() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['yearsales'];
            totalSum2 = totalSum2 + gridData[i]['yearsh'];
        }

        let closingPercent = 0;

        totalSum2 == 0 ? closingPercent = 0 : closingPercent = totalSum1 / totalSum2;

        // return the calculated value

        return closingPercent;

    }

    function customAggregateFnAvg() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['totalVolume'];
            totalSum2 = totalSum2 + gridData[i]['totalSales'];
        }

        let avg = 0;

        totalSum2 == 0 ? avg = 0 : avg = totalSum1 / totalSum2;

        // return the calculated value
        return avg;

    }


    function customAggregateFnAvgqs() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['volumenqs'];
            totalSum2 = totalSum2 + gridData[i]['salesqs'];
        }

        let avg = 0;

        totalSum2 == 0 ? avg = 0 : avg = totalSum1 / totalSum2;

        // return the calculated value
        return avg;

    }


    function customAggregateFnAvgnqs() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['volnqs'];
            totalSum2 = totalSum2 + gridData[i]['salesnqs'];
        }

        let avg = 0;

        totalSum2 == 0 ? avg = 0 : avg = totalSum1 / totalSum2;

        // return the calculated value
        return avg;

    }


    function customAggregateFnAvgCts() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['volumencts'];
            totalSum2 = totalSum2 + gridData[i]['salescts'];
        }

        let avg = 0;

        totalSum2 == 0 ? avg = 0 : avg = totalSum1 / totalSum2;

        // return the calculated value
        return avg;

    }
    // Fin Reporte

    useEffect(() => {
        getPuestosData()
        dispatch(setTitle(t('menusidebar.label.sales-room-statistics')));
    }, []);

    return (

        <div className="container-fluid">
            <ComboHotelSala />
            <div className="row col-md-12">
                <div className="col-md-3 form-group">
                    <label >Date start:</label>
                    <SemanticDatepicker className="col-md-12" name="dateStart" value={dateStart} onChange={onChangeDateStart} />

                </div>
                <div className="col-md-3  form-group ">

                    <label>Date end:</label>
                    <SemanticDatepicker className="col-md-12" name="dateEnd" value={dateEnd} onChange={onChangeDateEnd} />

                </div>
                <div className="col-md-6 form-group">
                    <label>Jobs:</label>
                    <MultiSelectComponent id="checkbox" ref={(scope) => { mulObjPuestos = scope; }} dataSource={puestosData}
                        fields={checkFields} placeholder="Select estatus" mode="CheckBox" showSelectAll={true} value={[]}
                        showDropDownIcon={true} filterBarPlaceholder="Search estatus" popupHeight="350px"                    >
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </div>
                <div className="col-md-6  form-group">

                    <Button primary onClick={handleViewButton}>View Report</Button>
                    <Button positive onClick={handleExport2Excel}>Export Report Full</Button>
                    <Button positive onClick={exportExcelEfficiencyFromAPI}>Export sales room efficiency</Button>
                    {/*<Button.Group className="col-md-3" color='orange'>
                        <Button onClick={handleExport2Excel}>Print Report</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={options}
                            onChange={handleButtonOption}
                            trigger={<></>}
                        />
                        </Button.Group>*/}

                </div>
            </div>
            <div>

                <GridComponent id="grid" height='100%' width='auto'
                    dataSource={rowData}
                    allowSorting={true}
                    allowResizing={true}
                    allowExcelExport={true}
                    allowPdfExport={false}

                    groupSettings={groupOptions}
                    allowGrouping={true}
                    toolbarClick={handleToolbarClick}
                    toolbar={toolbarOptions}
                    dataBound={dataBound}
                    showColumnChooser={true}
                    ref={g => grid = g}
                >
                    <Inject services={[Sort, Toolbar, Resize, ExcelExport, PdfExport, Aggregate, Group, ColumnChooser]} />
                    <ColumnsDirective>
                        <ColumnDirective field='prId' headerText="Id" width='10' textAlign="Right" />
                        <ColumnDirective field='prName' headerText="Nombre" width='30' textAlign="Left" />
                        <ColumnDirective field='puesto' headerText="Puesto" width='30' visible={false} textAlign="Left" />
                        <ColumnDirective field='qs' headerText="Qs" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='salesqs' headerText="Ventas Qs" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='volumenqs' headerText="Volumen Qs" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='closingPercentqs' headerText="%CI Qs" width='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='effqs' headerText="Eficiencia Qs" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='avgqs' headerText="Vta. Promedio Qs" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='q1s' headerText="Q1s" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='salesq1s' headerText="Ventas Q1s" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='volumenq1s' headerText="Volumen Q1s" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='closingPercentq1s' headerText="%CI Q1s" width='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='effq1s' headerText="Eficiencia Q1s" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='avgq1s' headerText="Vta. Promedio Q1s" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='nqs' headerText="NQs" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='salesnqs' headerText="Ventas NQs" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='volnqs' headerText="Volumen NQs" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='closingPercentnqs' headerText="%CI NQs" width='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='effnqs' headerText="Eficiencia NQs" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='avgnqs' headerText="Vta. Promedio NQs" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='ct' headerText="CTs" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='salescts' headerText="Ventas CTs" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='volumencts' headerText="Volumen CTs" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='closingPercentcts' headerText="%CI CTs" width='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='effcts' headerText="Eficiencia CTs" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='avgcts' headerText="Vta. Promedio CTs" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='sh' headerText="T. shows" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='wo' headerText="W/o" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='sales' headerText="Ventas" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='processable' headerText="Processable" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='oop' headerText="OOP" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='oopVolume' headerText="Volumen OOP" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='cancelled' headerText="Cancelados" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='cancelledVolume' headerText="Volumen Cancelados" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='cancelPercent' headerText="%Cancelados" width='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='totalSales' headerText="Ventas totales" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='totalVolume' headerText="Volumen total " width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='eff' headerText="Eficencia" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='closingPercent' headerText="%Cierre" width='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='avg' headerText="Venta promedio" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='pending' headerText="Pendientes" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='pendingVolume' headerText="Volumen Pendiente" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='closingcost' headerText="CC" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='volumentotal' headerText="% Volumen" width='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='shatendidas' headerText="% Sh" width='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='qsatendidas' headerText="% Qs" width='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='eficienciatotal' headerText="% VPG" width='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='yearqs' headerText="YearQs" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='yearnqs' headerText="YearNQs" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='yearcts' headerText="YearCTs" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='yearsh' headerText="Yearsh" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='yearsales' headerText="YearSales" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='yearsalesamount' headerText="YearTotalAmount" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='yearclanual' headerText="Year %CI" width='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='yeareffanual' headerText="YearEffAnual" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='yearsalespender' headerText="YearPendSales" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='yearsalespenderamount' headerText="YearPendAmount" width='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='yearallsalespender' headerText="AllPendSales" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='yearallSalespenderamount' headerText="AllPendAmount" width='20' textAlign="Right" format="C2" />
                    </ColumnsDirective>
                    <AggregatesDirective>
                        <AggregateDirective>
                            <AggregateColumnsDirective>
                                <AggregateColumnDirective field='qs' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='q1s' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='nqs' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='ct' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='sh' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='wo' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='sales' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='processable' format="C2" type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='oop' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='oopVolume' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='cancelled' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='cancelledVolume' format="C2" type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='cancelPercent' type='Custom' format='P2' customAggregate={customAggregateFnCl} />
                                <AggregateColumnDirective field='totalSales' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='totalVolume' format="C2" type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='eff' type='Custom' format="C2" customAggregate={customAggregateFnEff} />
                                <AggregateColumnDirective field='closingPercent' type='Custom' format='P2' customAggregate={customAggregateFnClo} />
                                <AggregateColumnDirective field='avg' type='Custom' format="C2" customAggregate={customAggregateFnAvg} />
                                <AggregateColumnDirective field='salesqs' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='salesq1s' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='salesnqs' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='salescts' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='volumenqs' format="C2" type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='volumenq1s' format="C2" type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='volnqs' format="C2" type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='volumencts' format="C2" type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='closingPercentqs' type='Custom' format='P2' customAggregate={customAggregateFnCloqs} />
                                <AggregateColumnDirective field='closingPercentnqs' type='Custom' format='P2' customAggregate={customAggregateFnClonqs} />
                                <AggregateColumnDirective field='closingPercentcts' type='Custom' format='P2' customAggregate={customAggregateFnClocts} />
                                <AggregateColumnDirective field='effqs' type='Custom' format="C2" customAggregate={customAggregateFnEffQs} />
                                <AggregateColumnDirective field='effnqs' type='Custom' format="C2" customAggregate={customAggregateFnEffNQs} />
                                <AggregateColumnDirective field='effcts' type='Custom' format="C2" customAggregate={customAggregateFnEffCts} />
                                <AggregateColumnDirective field='avgqs' type='Custom' format="C2" customAggregate={customAggregateFnAvgqs} />
                                <AggregateColumnDirective field='avgnqs' type='Custom' format="C2" customAggregate={customAggregateFnAvgnqs} />
                                <AggregateColumnDirective field='avgcts' type='Custom' format="C2" customAggregate={customAggregateFnAvgCts} />
                                <AggregateColumnDirective field='pending' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='pendingVolume' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='closingcost' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='volumentotal' type='Sum' format='P2' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='shatendidas' type='Sum' format='P2' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='qsatendidas' type='Sum' format='P2' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='yearqs' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='yearnqs' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='yearcts' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='yearsh' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='yearsales' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='yearsalesamount' format="C2" type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='yearclanual' type='Custom' format='P2' customAggregate={customAggregateFnCloAnual} />
                                <AggregateColumnDirective field='yeareffanual' type='Custom' format="C2" customAggregate={customAggregateFnEffAnual} />
                                <AggregateColumnDirective field='yearsalespender' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='yearsalespenderamount' type='Sum' format="C2" customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='yearallsalespender' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='yearallSalespenderamount' type='Sum' format="C2" customAggregate={footeAvailsSum} />
                            </AggregateColumnsDirective>
                        </AggregateDirective>
                    </AggregatesDirective>
                </GridComponent>
            </div>
        </div>
    );


}

export default SalesRoomStats;
