import * as actionTypes from './../../store/actions';
import HttpClient from './../../services/httpClient';

export const getData = (url, props) => {
    return (dispatch) => {

        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(getDataSuccess(response.data));
                    resolve(response);
                })
                .catch(error => {
                    let errorDescription = 'Error when load data';
                    eject(errorDescription);
                })
        });

    }
}

const getDataSuccess = (data) => {
    return {
        type: actionTypes.GET_DATA_SUCCESS,
        data: data
    }
}

export const setIdSaleRoom = (id) => ({
    type: actionTypes.DATA_SET_SALE_ROOM,
    payload: id
});

export const setIdHotel = (id) => ({
    type: actionTypes.DATA_SET_HOTEL,
    payload: id
})

export const setIdAgencia = (id) => ({
    type: actionTypes.DATA_SET_AGENCIA,
    payload: id
});

export const setIdPais = (id) => ({
    type: actionTypes.DATA_SET_PAIS,
    payload: id
});

export const setIdUser = (id) => ({
    type: actionTypes.DATA_SET_USER,
    payload: id
});