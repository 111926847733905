import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ColumnDirective, ColumnsDirective,
    GridComponent, Grid, Inject, Page, Sort, Filter, Resize,
    Toolbar, ExcelExport, PdfExport
} from '@syncfusion/ej2-react-grids';
import { Aggregate, ForeignKey, getForeignData, AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import HttpClient from './../../../services/httpClient';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Dropdown, Select } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { useTranslation } from "react-i18next";
import { finishLoading, setTitle, startLoading } from "./../../../store/actions/uiAction";
import { logReportService } from "./../../../services/logReportService";
import ComboUsuariosSala from "./../../../components/combo-usuarios/combo-usuarios";

const ContractTime = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [rowData, setRowData] = useState([]);
    const [NameSala, setNameSala] = useState([]);
    const [NameHotel, setNameHotel] = useState([]);
    const user = useSelector(state => state.auth.currentUser);
    const { idSaleRoom,  idUser} = useSelector(state => state.user.param);
    let grid = null;

    const options = [
        { key: 'excel', icon: 'edit', text: 'Export to excel', value: 'excel' },
        { key: 'pdf', icon: 'delete', text: 'Export to pdf', value: 'pdf' },
    ]

    const onChangeDateStart = (event, data) => setDateStart(data.value);
    const onChangeDateEnd = (event, data) => setDateEnd(data.value);

    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const handleViewButton = () => {
        getManifestReport();
    }

    const getManifestReport = () => {
        const date1 = dateStart.toISOString().slice(0, 10).replace(/-/g, '');
        const date2 = dateEnd.toISOString().slice(0, 10).replace(/-/g, '');

        getDataReport(`/catalog/getNameSalavtaId/${idSaleRoom}`).then((response) => {
            if (response.status === 200) {   
                setNameSala(response.data);
            }
        })
       

        getDataReport(`/reports/contracts-time/${date1}/${date2}/${idSaleRoom}/${idUser}`).then((response) => {
            if (response.status === 200) {
                setRowData(response.data);
                logReportService.add('Contract time', user);
            }
        })
    }

    // Reporte
    const handleButtonOption = (opt) => {
        if (grid && opt.target.innerText === 'Export to excel') {
            export2Excel();
        } else {
            if (grid && opt.target.innerText === 'Export to pdf') {
                grid.pdfExport();
            }
        }
    }

    const export2Excel = () => {
        const date1 = dateStart.toDateString();
        const date2 = dateEnd.toDateString();
        const excelExportProperties = {
            fileName:  t('menusidebar.label.contracts-time')+".xlsx",
            theme: {
                caption: { fontName: 'Calibri', fontColor: '#666666' , fontSize: 13},
                header: { fontName: 'Calibri', fontColor: '#000000', backColor: '#3F97BF', fontSize: 13 },
            },
            header: {
                headerRows: 5,
                rows: [
                    { cells: [{ colSpan: 4, value: "LVC KCS", style: { fontColor: '#000000', fontSize: 16, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: t('menusidebar.label.contracts-time'), style: { fontColor: '#000000', fontSize: 14, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "SALES ROOM: "+NameSala[0]["name"], style: { fontColor: '#000000', fontSize: 14, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value:  date1+" - "+date2, style: { fontColor: '#000000', fontSize: 14, hAlign: 'Left', bold: true, } }] },   

                ]
            }
        }
        grid.excelExport(excelExportProperties);
    }

    const handleExport2Excel = () => {
        if (grid) {
            export2Excel();
        }
    }


    const handleToolbarClick = (args) => {
        if (grid && args.item.id === 'grid_excelexport') {
            export2Excel();
        } else {
            if (grid && args.item.id === 'grid_pdfexport') {
                grid.pdfExport();
            }
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns();
        }
    }

   
    const convertMinsToTime = (mins) => {
        let hours = Math.floor(mins / 60);
        let minutes = mins % 60;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}hrs:${minutes}mins`;
      }

    function customAggregateFnAvg() {

        var   gridData = grid.dataSource; 
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var minutos = 0;
        
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            minutos = minutos + gridData[i]['minutos'];
          
        }
        console.log("minutos",minutos)
        console.log("length",gridData.length)
        
         let avg =  0;

         minutos == 0?  avg =0 : avg= minutos / gridData.length;

         return convertMinsToTime(avg)
       

    }


    // Fin Reporte

    useEffect(() => {
        dispatch(setTitle(t('menusidebar.label.contracts-time')));
    }, [])

    return (
        <div className="container-fluid">
             <ComboUsuariosSala verSala="valido" idPuesto="10" idDepto="0" idPermiso ="0" name="VLO" />
          
            <div className="row">
            <div className="col-md-3 form-group">
                    <label >Date start:</label>
                    <SemanticDatepicker className="col-md-12" name="dateStart" value={dateStart} onChange={onChangeDateStart} />

                </div>
                <div className="col-md-3  form-group ">

                    <label >Date end:</label>
                    <SemanticDatepicker className="col-md-12" name="dateEnd" value={dateEnd} onChange={onChangeDateEnd} />

                </div>
                <div className="col-md-6  form-group">
                    
                    <Button primary onClick={handleViewButton}>View Report</Button>
                    <Button   positive onClick={handleExport2Excel}>Export Report</Button>
                       {/*<Button.Group className="col-md-3" color='orange'>
                        <Button onClick={handleExport2Excel}>Print Report</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={options}
                            onChange={handleButtonOption}
                            trigger={<></>}
                        />
                        </Button.Group>*/}


                </div>      
            </div>
            <div>
                <GridComponent id="grid" height='100%' width='auto'
                    dataSource={rowData}
                    allowSorting={true}
                    allowResizing={true}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={false}
                    toolbarClick={handleToolbarClick}
                    dataBound={dataBound}
                    ref={g => grid = g}
                >
                    <Inject services={[Sort, Toolbar, Resize, ExcelExport, PdfExport, Aggregate]} />
                    <ColumnsDirective>
                        <ColumnDirective field='rownum' headerText="Num" minWidth='5' textAlign="Right" />                     
                        <ColumnDirective field='membresia' headerText="Membresia" minWidth='40' textAlign="Right" />
                        <ColumnDirective field='statuscto' headerText="Status Cto" minWidth='10' textAlign="Right" />
                        <ColumnDirective field='fechainicio' headerText="FechaInicio" Width='10' textAlign="Left"  />
                        <ColumnDirective field='horainicio' headerText="HoraInicio" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='fechafin' headerText="FechaFin" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='horafin' headerText="HoraFin" minWidth='10' textAlign="Left"/>
                        <ColumnDirective field='tiempo' headerText="Tiempo" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='vlo' headerText="Vlo" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='comments' headerText="Comments" minWidth='100' textAlign="Left" AutoFit/>
                    </ColumnsDirective>
                    <AggregatesDirective>
                        <AggregateDirective>
                            <AggregateColumnsDirective>
                            <AggregateColumnDirective field='tiempo' type='Custom'  customAggregate={customAggregateFnAvg} />   
                        </AggregateColumnsDirective>
                        </AggregateDirective>
                    </AggregatesDirective>
                </GridComponent>
            </div>
        </div>
    );
}

export default ContractTime;
