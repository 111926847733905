import React, { useEffect } from 'react';

const ReportViewer = ({reportSource}) => {
    const servicePath  = process.env.REACT_APP_URL_API_PATH + '/report-storage';

    useEffect(() => {
        window.jQuery('#reportViewer1')
            .telerik_ReportViewer({
                serviceUrl: servicePath,
                reportSource: {
                    report: reportSource
                },
                scale: 1.0,
                viewMode: 'INTERACTIVE',
                printMode: 'SPECIFIC',
                sendEmail: { enabled: false }
            });
    }, []);

    return (
        <div id="reportViewer1"></div>
    );
};

export default ReportViewer;