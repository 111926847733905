import React from 'react';
import Notifications from './notifications-dropdown/NotificationsDropdown';
import Languages from './languages-dropdown/LanguagesDropdown';
import User from './user-dropdown/UserDropdown';
import {useSelector} from "react-redux";

const Header = ({toggleMenuSidebar}) => {
    const {user} = useSelector(state => state.auth.currentUser);
    const {msgTitle} = useSelector(state => state.ui);

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <button
                        onClick={() => toggleMenuSidebar()}
                        type="button"
                        className="nav-link"
                        data-widget="pushmenu"
                        href="#"
                    >
                        <i className="fas fa-bars" />
                    </button>
                </li>
            </ul>
            <div className="col-md-3">
                <h2 className="pl-2">{msgTitle}</h2>
            </div>
            <ul className="navbar-nav ml-auto">
                <Notifications />
                <Languages />
                <User user={user}/>
            </ul>
        </nav>
    );
};

export default Header;
