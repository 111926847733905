import React, {useEffect, useState} from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import HttpClient from './../../../services/httpClient';
import { Dropdown } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {logReportService} from "./../../../services/logReportService";
import {setTitle} from "./../../../store/actions/uiAction";
import ComboHotelSala from "./../../../components/combo-hotel-sala/combo-hotel-sala";

const CancellationReport = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [currentDate, setNewDate] = useState(null);
    const onChange = (event, data) => setNewDate(data.value);
    const user = useSelector(state => state.auth.currentUser);
    const {idSaleRoom, idHotel} = useSelector(state => state.user.param);

    const optionsButton = [
        { key: 'excel', icon: 'edit', text: 'Export to excel', value: 'excel' },
        { key: 'pdf', icon: 'delete', text: 'Export to pdf', value: 'pdf' },
    ]

    const getData = (url) => {
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    eject(error);
                })
        });
    }

    const getReport = () => {
        getData(`/reports/active-personnel/1`).then((response) => {
            if (response.status === 200) {
              //  setRowData(response.data);
                logReportService.add('Cancellation Report', user);
            }
        })
    }

    const handleViewButton = () => {
        getReport();
    }

    useEffect(() => {
        dispatch(setTitle(t('menusidebar.label.cancellation-report')));
    }, [])


    return (
        <div className="container-fluid">
            <ComboHotelSala />
            <div className="row pb-3">
                <div className="col-md-5">

                </div>
                <div className="col-md-4">
                    <div className="col-md-12">
                        <Button className="col-md-4 mb-1" primary onClick={handleViewButton}>View Report</Button>
                        <Button.Group className="col-md-4 mb-1" color='orange'>
                            <Button onClick={handleViewButton}>Print Report</Button>
                            <Dropdown
                                className='button icon'
                                floating
                                options={optionsButton}
                                trigger={<></>}
                            />
                        </Button.Group>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="ag-theme-alpine report-table">
                    <AgGridReact
                        rowData={rowData}>
                        <AgGridColumn field="id"  sortable={ true } filter={ true }></AgGridColumn>
                        <AgGridColumn field="name" sortable={ true } filter={ true } width={500} resizable={true}></AgGridColumn>
                        <AgGridColumn field="registrationDate" sortable={ true } filter={ true }></AgGridColumn>
                    </AgGridReact>
                </div>
            </div>
        </div>
    );
}

export default CancellationReport;
