import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProcessorReportDropdown = () => {
    const dropdownRef = useRef(null);
    const { t } = useTranslation();

    const [dropdownState, updateDropdownState] = useState({
        isDropdownOpen: false
    });

    const toggleDropdown = () => {
        updateDropdownState({ isDropdownOpen: !dropdownState.isDropdownOpen });
    };

    let className = 'nav-item';
    if (dropdownState.isDropdownOpen) {
        className = 'nav-item menu-is-opening menu-open';
    } else {
        className = 'nav-item';
    }

    return (
        <li ref={dropdownRef} className={className}>
            <a
                className="nav-link"
                onClick={toggleDropdown}
            >
                <i className="nav-icon fas fa-copy" />
                <p>
                    {t('menusidebar.label.processor-report')}
                    <i className="fas fa-angle-left right" />
                </p>
            </a>
            <ul className="nav nav-treeview">
                {/*<li className="nav-item">
                    <Link to="/graphic-production" className="nav-link">
                        <i className="fa fa-bar-chart nav-icon" />
                        <p>{t('menusidebar.label.graphic-production')}</p>
                    </Link>
                </li>*/}
                <li className="nav-item">
                    <Link to="/production-agency" className="nav-link">
                        <i className="fa fa-address-book nav-icon" />
                        <p>{t('menusidebar.label.production-agency')}</p>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/production-country" className="nav-link">
                        <i className="fa fa-globe nav-icon" />
                        <p>{t('menusidebar.label.production-country')}</p>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/pr-production" className="nav-link">
                        <i className="fa fa-id-badge nav-icon" />
                        <p>{t('menusidebar.label.pr-production')}</p>
                    </Link>
                </li>
                {/*<li className="nav-item">
                    <Link to="/unavailable-arrival" className="nav-link">
                        <i className="fa fa-exclamation-circle nav-icon" />
                        <p>{t('menusidebar.label.unavailable-arrival')}</p>
                    </Link>
                </li>*/}
                <li className="nav-item">
                    <Link to="/contracts-time" className="nav-link">
                        <i className="fa fa-clock-o nav-icon" />
                        <p>{t('menusidebar.label.contracts-time')}</p>
                    </Link>
                </li>
            </ul>
        </li>
    );
};

export default ProcessorReportDropdown;
