import React from 'react';
import classes from './PageLoading.module.scss';

const PageLoading = () => {
    return (
        <div className={classes.loading}>
            <span>T</span>
            <span>R</span>
            <span>A</span>
            <span>V</span>
            <span>E</span>
            <span>L</span>
            <span></span>
            <span>Y</span>
            <span>O</span>
            <span>U</span>
            <span>R</span>
            <span></span>
            <span>W</span>
            <span>A</span>
            <span>Y</span>
        </div>
    );
};

export default PageLoading;
