import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ColumnDirective, ColumnsDirective,
    GridComponent, Grid, Inject, Page, Sort, Filter, Resize,
    Toolbar, ExcelExport, PdfExport, ColumnChooser
} from '@syncfusion/ej2-react-grids';
import { AggregatesDirective, Aggregate, AggregateColumnsDirective, AggregateDirective, AggregateColumnDirective } from '@syncfusion/ej2-react-grids';

import HttpClient from './../../../services/httpClient';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Dropdown, Select } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { useTranslation } from "react-i18next";
import { finishLoading, setTitle, startLoading } from "./../../../store/actions/uiAction";
import { logReportService } from "./../../../services/logReportService";
import ComboHotelSala from "./../../../components/combo-hotel-sala/combo-hotel-sala";
import { MultiSelectComponent, CheckBoxSelection, Inject as InjectDrop } from '@syncfusion/ej2-react-dropdowns';

const ContractAnalyst = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [numCto, setNumCto] = useState();
    const [rowData, setRowData] = useState([]);
    const [NameSala, setNameSala] = useState([]);
    const [NameHotel, setNameHotel] = useState([]);
    const [NameEstatusContract, setNameEstatusContract] = useState([]);
    const [NameTipoVta, setNameTipoVta] = useState([]);

    const user = useSelector(state => state.auth.currentUser);
    const { idSaleRoom, idHotel } = useSelector(state => state.user.param);
    const [estatusContratosData, setEstatusContratosData] = useState([]);
    const [tiposVtaData, setTiposVtaData] = useState([]);
    const toolbarOptions = ['ColumnChooser'];

    let grid = null;
    let mulObjEstatus = [];
    let mulObjtiposVta = [];

    const options = [
        { key: 'excel', icon: 'edit', text: 'Export to excel', value: 'excel' },
        { key: 'pdf', icon: 'delete', text: 'Export to pdf', value: 'pdf' },
    ];

    const checkFields = { text: 'Name', value: 'Code' };

    const onChangeDateStart = (event, data) => setDateStart(data.value);
    const onChangeDateEnd = (event, data) => setDateEnd(data.value);
    const onNumCto = (event, data) => setNumCto(event.target.value);

    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const handleViewButton = () => {
        getContractAnalysReport();
    }

    const getContractAnalysReport = () => {

        const numCto_ = (numCto != null) ? numCto : "0";

        const date1 = dateStart ? dateStart.toISOString().slice(0, 10).replace(/-/g, '') : '0';
        const date2 = dateEnd ? dateEnd.toISOString().slice(0, 10).replace(/-/g, '') : '0';

        const estatatusleads = (mulObjEstatus != null && mulObjEstatus.value.length > 0) ? mulObjEstatus.value.map(String).toString() : "0";

        const tiposvta = (mulObjtiposVta != null && mulObjtiposVta.value.length > 0) ? mulObjtiposVta.value.map(String).toString() : "0";

        getDataReport(`/catalog/getNameSalavtaId/${idSaleRoom}`).then((response) => {
            if (response.status === 200) {   
                setNameSala(response.data);
            }
        })
        
        getDataReport(`/catalog/getNameHotelId/${idHotel}`).then((response) => {
            if (response.status === 200) {   
                setNameHotel(response.data);
            }
        })

        getDataReport(`/catalog/getNameEstatusContratoId/${estatatusleads}`).then((response) => {
            if (response.status === 200) {   
                setNameEstatusContract(response.data);
            }
        })

        getDataReport(`/catalog/getNameTipoVentaId/${tiposvta}`).then((response) => {
            if (response.status === 200) {
                setNameTipoVta(response.data);
            }
        })

        getDataReport(`/reports/contract-analyst/${date1}/${date2}/${idSaleRoom}/${idHotel}/${estatatusleads}/${numCto_}/${tiposvta}`).then((response) => {
            if (response.status === 200) {
                setRowData(response.data);
                logReportService.add('Contract Analyst Report', user);
            }
        })
    }

    const pageOptions = {
        pageSize: 20, pageSizes: true
    };

    // Reporte
    const handleButtonOption = (opt) => {
        if (grid && opt.target.innerText === 'Export to excel') {
            export2Excel();
        } else {
            if (grid && opt.target.innerText === 'Export to pdf') {
                grid.pdfExport();
            }
        }
    }

    const export2Excel = () => {
        const date1 = dateStart.toDateString();
        const date2 = dateEnd.toDateString();
        const excelExportProperties = {
            fileName: t('menusidebar.label.contract-analyst') + ".xlsx",
            theme: {
                caption: { fontName: 'Calibri', fontColor: '#666666', fontSize: 13 },
                header: { fontName: 'Calibri', fontColor: '#000000', backColor: '#3F97BF', fontSize: 13 },
            },
            header: {
                headerRows: 7,
                rows: [
                    { cells: [{ colSpan: 4, value: "LVC KCS "+ t('menusidebar.label.contract-analyst'), style: { fontColor: '#000000', fontSize: 16, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "SALES ROOM: "+NameSala[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "HOTELS: "+NameHotel[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "SALES TYPE: " + NameTipoVta[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "STATUS CONTRACT: "+NameEstatusContract[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: date1 + " - " + date2, style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },

                ]
            }
        }
        grid.excelExport(excelExportProperties);
    }

    const handleExport2Excel = () => {
        if (grid) {
            export2Excel();
        }
    }

    const handleToolbarClick = (args) => {
        if (grid && args.item.id === 'grid_excelexport') {
            export2Excel();
        } else {
            if (grid && args.item.id === 'grid_pdfexport') {
                grid.pdfExport();
            }
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns();
        }
    }
    function footerSum(props) {
        return (<span>{props.Sum}</span>);

    }

    const getData = (url, data) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url, data)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const getOptionData = (url) => {
        return new Promise((resolve, eject) => {
            getData(url).then((response) => {
                if (response.status === 200) {
                    let obj = response.data;
                    let res = [];
                    for (let i in obj) {
                        let item = { Code: obj[i].id, Name: obj[i].name };
                        res.push(item)
                    }

                    resolve(res);
                }
            })
        })

    }
    const getEstatusContratosData = () => {
        getOptionData('/catalog/estatuscontratos/0')
            .then((response) => {
                setEstatusContratosData(response);
            })
    }

    const getTiposVtaData = () => {
        getOptionData('/catalog/tiposvta')
            .then((response) => {
                setTiposVtaData(response);
            })
    }
    // Fin Reporte

    useEffect(() => {
        getEstatusContratosData();
        getTiposVtaData();
        dispatch(setTitle(t('menusidebar.label.contract-analyst')));
    }, []);

    return (
        <div className="container-fluid">
            <ComboHotelSala />
            <div className="row col-md-12">
                <div className="col-md-3 form-group">
                    <label >Date start:</label>
                    <SemanticDatepicker className="col-md-12" name="dateStart" value={dateStart} onChange={onChangeDateStart} />

                </div>
                <div className="col-md-3 form-group ">
                    <label >Date end:</label>
                    <SemanticDatepicker className="col-md-12" name="dateEnd" value={dateEnd} onChange={onChangeDateEnd} />

                </div>
                <div className="col-md-6 form-group">
                    <label>Sales types:</label>
                    <MultiSelectComponent id="checkbox" ref={(scope) => { mulObjtiposVta = scope; }} dataSource={tiposVtaData}
                        fields={checkFields} placeholder="Select type" mode="CheckBox" showSelectAll={true}
                        showDropDownIcon={true} filterBarPlaceholder="Search estatus" popupHeight="350px" value={[]}              >
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </div>
                <div className="col-md-6 form-group">
                    <label>Status contract:</label>
                    <MultiSelectComponent id="checkbox" ref={(scope) => { mulObjEstatus = scope; }} dataSource={estatusContratosData}
                        fields={checkFields} placeholder="Select estatus" mode="CheckBox" showSelectAll={true}
                        showDropDownIcon={true} filterBarPlaceholder="Search estatus" popupHeight="350px" value={[]}              >
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </div>              
                <div className="col-md-6 form-group">
                    <label>NumCto:</label>
                    <input type='text' name="numCto" className="col-md-6 form-control" onChange={onNumCto} ></input>
                </div>
                <div className="col-md-6 form-group">
                    <Button primary onClick={handleViewButton}>View Report</Button>
                    <Button positive onClick={handleExport2Excel}>Export Report</Button>
                    {/*<Button.Group className="col-md-3" color='orange'>
                        <Button onClick={handleExport2Excel}>Print Report</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={options}
                            onChange={handleButtonOption}
                            trigger={<></>}
                        />
                        </Button.Group>*/}

                </div>
            </div>
            <div>
                <GridComponent id="grid" height='100%' width='auto'
                    dataSource={rowData}
                    allowSorting={true}
                    allowResizing={true}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={toolbarOptions}
                    showColumnChooser={true}
                    toolbarClick={handleToolbarClick}
                    dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={pageOptions}
                    ref={g => grid = g}
                >
                    <Inject services={[Sort, Toolbar, Resize, ExcelExport, PdfExport, Aggregate, Page,ColumnChooser]} />
                    <ColumnsDirective>
                        <ColumnDirective field='rownum' headerText="Num" minWidth='5' textAlign="Right" />
                        <ColumnDirective field='lead' headerText="Lead" minWidth='20' textAlign="Right" />
                        <ColumnDirective field='nombre' headerText="Member" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='sala' headerText="Room" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='location' headerText="Location" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='calif' headerText="Calif" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='foliocon' headerText="Folio" minWidth='5' textAlign="Right" />
                        <ColumnDirective field='salesDate' headerText="Sales Date" Width='10' textAlign="Right" />
                        <ColumnDirective field='procDate' headerText="Proc Date" minWidth='10' textAlign="Right" format="dMy" />
                        <ColumnDirective field='fechacxl' headerText="Cxl Date" minWidth='10' textAlign="Right" format="dMy" />
                        <ColumnDirective field='membership' headerText="Membership" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='mt' headerText="Member type" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='tipoVta' headerText="Sale type" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='nombre' headerText="Name" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='estatus' headerText="Status" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='salesPrice' headerText="Sales Price" minWidth='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='dp' headerText="Dp agreed" minWidth='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='dpProcent' headerText="Dp agreed%" minWidth='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='engancheTDC' headerText="DP Debit/Credit" minWidth='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='engancheVCM' headerText="DP VCM" minWidth='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='dpCollected' headerText="dpCollected" minWidth='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='dpCollectedPorcent' headerText="dpCollected%" minWidth='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='enganchePendiente' headerText="DP pending" minWidth='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='moneda' headerText="Currency" minWidth='50' textAlign="Left" />
                        <ColumnDirective field='pr' headerText="C1" Width='10' textAlign="Left" />
                        <ColumnDirective field='pr_2' headerText="C2" Width='10' textAlign="Left" />
                        <ColumnDirective field='liner' headerText="Liner" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='ftm' headerText="FTM" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='ftb' headerText="FTB" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='closer' headerText="Closer 1" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='closer_2' headerText="Closer 2" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='exitCloser' headerText="Exit Closer 1" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='exitCloser_2' headerText="Exit Closer 2" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='vlo' headerText="VLO" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='comentario' headerText="Comments" minWidth='30' textAlign="Left" />
                    </ColumnsDirective>
                    <AggregatesDirective>
                        <AggregateDirective>
                            <AggregateColumnsDirective>
                                <AggregateColumnDirective field='salesPrice' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='dp' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='dpCollected' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='engancheTDC' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='engancheVCM' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='enganchePendiente' type='Sum' format="C2" customAggregate={footerSum} />
                            </AggregateColumnsDirective>
                        </AggregateDirective>
                    </AggregatesDirective>
                </GridComponent>
            </div>
        </div>
    );
}

export default ContractAnalyst;
