import React from "react";
import { Route, Redirect } from "react-router-dom";
import {useSelector} from "react-redux";

function PrivateRouter({ component: Component, ...rest }) {
    const { token, isLoggedIn } = useSelector(store => store.auth);

    return (
        <Route
            {...rest}
            render = { (props) =>
                token ? (
                    isLoggedIn === true ? (
                            <Component {...props} {...rest} />
                        )
                        : <Redirect to="/login" />
                ): <Redirect to="/login" />
            }
        />
    );
}

export default PrivateRouter;