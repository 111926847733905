import React, { useState, useEffect } from 'react';
import {
    ColumnDirective, ColumnsDirective,
    GridComponent, Grid, Group, Inject, Page, Sort, Filter, Resize,
    Toolbar, ExcelExport, PdfExport
} from '@syncfusion/ej2-react-grids';
import HttpClient from '../../../services/httpClient';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Dropdown, Select } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logReportService } from "./../../../services/logReportService";
import { setTitle } from "./../../../store/actions/uiAction";
import ComboHotelSala from "./../../../components/combo-hotel-sala/combo-hotel-sala";

const PreManifest = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [rowData, setRowData] = useState([]);
    const [NameSala, setNameSala] = useState([]);
    const [NameHotel, setNameHotel] = useState([]);
    const user = useSelector(state => state.auth.currentUser);
    const { idSaleRoom, idHotel } = useSelector(state => state.user.param);
    let grid = null;

    const options = [
        { key: 'excel', icon: 'edit', text: 'Export to excel', value: 'excel' },
        { key: 'pdf', icon: 'delete', text: 'Export to pdf', value: 'pdf' },
    ]

    const groupOptions = {
        columns: ['hotel'],
        showDropArea: false
    };



    const onChangeDateStart = (event, data) => setDateStart(data.value);
    const onChangeDateEnd = (event, data) => setDateEnd(data.value);

    const getDataReport = (url) => {
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    eject(error);
                })
        });
    }



    const handleLocationChange = (e, { value }) => {

    }

    const handleViewButton = () => {
        getManifestReport();
    }

    const getManifestReport = () => {

        const date1 = dateStart.toISOString().slice(0, 10).replace(/-/g, '');
        const date2 = dateEnd.toISOString().slice(0, 10).replace(/-/g, '');


        getDataReport(`/catalog/getNameSalavtaId/${idSaleRoom}`).then((response) => {
            if (response.status === 200) {
                setNameSala(response.data);
            }
        })

        getDataReport(`/catalog/getNameHotelId/${idHotel}`).then((response) => {
            if (response.status === 200) {
                setNameHotel(response.data);
            }
        })

        getDataReport(`/reports/pre-manifiesto/${date1}/${date2}/${idSaleRoom}/${idHotel}`).then((response) => {
            if (response.status === 200) {
                setRowData(response.data);
                logReportService.add('Pre-Manifest Report', user);
            }
        })

    }

    // Reporte
    const handleButtonOption = (opt) => {
        if (grid && opt.target.innerText === 'Export to excel') {
            export2Excel();
        } else {
            if (grid && opt.target.innerText === 'Export to pdf') {
                grid.pdfExport();
            }
        }
    }

    const export2Excel = () => {
        const date1 = dateStart.toDateString();
        const date2 = dateEnd.toDateString();
        const excelExportProperties = {
            fileName: t('menusidebar.label.pre-manifest') + ".xlsx",
            theme: {
                caption: { fontName: 'Calibri', fontColor: '#666666', fontSize: 13 },
                header: { fontName: 'Calibri', fontColor: '#000000', backColor: '#3F97BF', fontSize: 13 },
            },
            header: {
                headerRows: 5,
                rows: [
                    { cells: [{ colSpan: 4, value: "LVC KCS "+ t('menusidebar.label.pre-manifest'), style: { fontColor: '#000000', fontSize: 16, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "SALES ROOM: "+NameSala[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "HOTELS: "+NameHotel[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: date1 + " - " + date2, style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                ]
            }
        }
        grid.excelExport(excelExportProperties);
    }

    const handleExport2Excel = () => {
        if (grid) {
            export2Excel();
        }
    }

    const handleToolbarClick = (args) => {
        if (grid && args.item.id === 'grid_excelexport') {
            export2Excel();
        } else {
            if (grid && args.item.id === 'grid_pdfexport') {
                grid.pdfExport();
            }
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns();
        }
    }
    // Fin Reporte

    useEffect(() => {

        dispatch(setTitle(t('menusidebar.label.pre-manifest')));
    }, []);

    return (
        <div className="container-fluid">
            <ComboHotelSala />
            <div className="row col-md-12">
                <div className="col-md-3 form-group">
                    <label >Date start:</label>
                    <SemanticDatepicker className="col-md-12" name="dateStart" value={dateStart} onChange={onChangeDateStart} />

                </div>
                <div className="col-md-3  form-group ">

                    <label >Date end:</label>
                    <SemanticDatepicker className="col-md-12" name="dateEnd" value={dateEnd} onChange={onChangeDateEnd} />

                </div>
                <div className="col-md-6  form-group">
                    <Button primary onClick={handleViewButton}>View Report</Button>
                    <Button positive onClick={handleExport2Excel}>Export Report</Button>
                    {/*<Button.Group className="col-md-3" color='orange'>
                        <Button onClick={handleExport2Excel}>Print Report</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={options}
                            onChange={handleButtonOption}
                            trigger={<></>}
                        />
                        </Button.Group>*/}
                </div>
            </div>
            <div>
                <GridComponent id="grid" height='100%' width='auto'
                    dataSource={rowData}
                    allowSorting={true}
                    allowResizing={true}
                    allowExcelExport={true}
                    groupSettings={groupOptions}
                    allowGrouping={true}
                    toolbar={false}
                    toolbarClick={handleToolbarClick}
                    dataBound={dataBound}
                    ref={g => grid = g}
                >
                    <Inject services={[Sort, Toolbar, Resize, ExcelExport, Group]} />
                    <ColumnsDirective>
                        <ColumnDirective field='folio' headerText="Folio" minWidth='10' textAlign="Right" />
                        <ColumnDirective field='habitacion' headerText="Room" minWidth='10' textAlign="center" />
                        <ColumnDirective field='nombre' headerText="Name" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='edad' headerText="Age" minWidth='10' textAlign="Right" />
                        <ColumnDirective field='idioma' headerText="Idiom" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='pais' headerText="Country" minWidth='15' textAlign="Left" />
                        <ColumnDirective field='agencia' headerText="Agency" minWidth='8' textAlign="Left" />
                        <ColumnDirective field='ocupacion' headerText="Occupation" minWidth='15' textAlign="Left" />
                        <ColumnDirective field='pr01NoEmployee' headerText="# Emp" minWidth='8' textAlign="Left" />
                        <ColumnDirective field='pr_01' headerText="CC1" minWidth='8' textAlign="Left" />
                        <ColumnDirective field='pr02NoEmployee' headerText="# Emp" minWidth='8' textAlign="Left" />
                        <ColumnDirective field='pr_02' headerText="CC2" minWidth='8' textAlign="Left" />
                        <ColumnDirective field='precalificacion' headerText="PreCal" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='fechaInv' headerText="Fecha Inv" minWidth='10' textAlign="center" />
                        <ColumnDirective field='horaInv' headerText="Time" minWidth='10' textAlign="center" format='hh:mm' />
                        <ColumnDirective field='hotel' headerText="Hotel" minWidth='8' textAlign="Left" />
                        <ColumnDirective field='sh' headerText="Sh" minWidth='10' textAlign="left" />
                        <ColumnDirective field='comentarios' headerText="Comments" minWidth='30' textAlign="Left" />
                    </ColumnsDirective>
                </GridComponent>
            </div>

        </div>
    );
}

export default PreManifest;
