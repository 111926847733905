import React, {useEffect, useState} from 'react';
import { Dropdown } from 'semantic-ui-react'
import {finishLoading, startLoading} from "./../../store/actions/uiAction";
import HttpClient from "./../../services/httpClient";
import {useDispatch} from "react-redux";
import {setIdUser, setIdSaleRoom} from "./../../store/actions/dataAction";

const ComboUsuariosSala = (props) => {
    const dispatch = useDispatch();
    const [salesRoomData, setSalesRoomData] = useState([]);
    const [salesRoom, setSalesRoom] = useState(0);
    const [userData, setUserData] = useState([]);
    const [user, setUser] = useState(0);

    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const getOptionData = (url) => {
        return new Promise((resolve, eject) => {
            getDataReport(url).then((response) => {
                if (response.status === 200) {
                    let obj = response.data;
                    let res = [];
                    for(let i in obj) {
                        let item = {key: obj[i].id, text: obj[i].name, value: obj[i].id};
                        res.push(item)
                    }

                    resolve(res);
                }
            })
        })
    }

    const getSalesRoomData = () => {
        getOptionData('/catalog/salasvta')
            .then((response) => {
                setSalesRoomData(response);
            })
    }

    const getUserData = (idSalaVta) => {
        getOptionData(`/catalog/usuarios/${idSalaVta}/${props.idPuesto}/${props.idDepto}/${props.idPermiso}`)
            .then((response) => {
                setUserData(response);
            })
    }

    const handleSalaVtaChange = (e, {value}) => {
        setSalesRoom(value);
        getUserData(value);
        dispatch(setIdSaleRoom(value));
        dispatch(setIdUser(0));
    }



    const handleUserChange = (e, {value}) => {
        setUser(value);
        dispatch(setIdUser(value));
    }

    useEffect(() => {
     
        getSalesRoomData();
        getUserData(0);
    }, []);

    return (
        <>
            <div className="row col-md-12">
        { props.verSala == 'valido' ?
            <div className="form-group col-md-6">
            <label>Sales room:</label>
            <Dropdown className="form-control" disabled={props.statusSala} placeholder='<< ALL SALES ROOM >>' selection
                    options={salesRoomData}
                    onChange={handleSalaVtaChange}
                    value={salesRoom}
            />
        </div>

        : <div></div>

        }                   

            <div className="form-group col-md-6">
                <label>{props.name}:</label>
                <Dropdown className="form-control" placeholder='<< ALL USERS >>' selection clearable search
                            options={userData}
                            onChange={handleUserChange}
                            value={user}
                />
            </div>

            </div>
        </>
    );
};

export default ComboUsuariosSala;
