import {applyMiddleware,createStore, combineReducers, compose } from 'redux';
import authReducer from './reducers/authReducer';
import dataReducer from "./reducers/dataReducer";
import uiReducer from "./reducers/uiReducer";
import thunk from 'redux-thunk'

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    user: dataReducer,
    ui: uiReducer
});

export const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(thunk),
    )
);


