// User Actions
export const OPEN_SESSION = 'OPEN_SESSION';
export const CLOSE_SESSION = 'CLOSE_SESSION';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOAD_USER = 'LOAD_USER';

// User Data Actions
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
export const POST_DATA_SUCCESS = 'POST_DATA_SUCCESS';
export const PUT_DATA_SUCCESS = 'PUT_DATA_SUCCESS';
export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';
export const DATA_SET_SALE_ROOM = 'DATA_SET_SALE_ROOM';
export const DATA_SET_HOTEL = 'DATA_SET_HOTEL';
export const DATA_SET_AGENCIA = 'DATA_SET_AGENCIA';
export const DATA_SET_PAIS = 'DATA_SET_PAIS';
export const DATA_SET_USER = 'DATA_SET_USER';
// UI Actions
export const UI_SET_ERROR = 'UI_SET_ERROR';
export const UI_REM_ERROR = 'UI_REM_ERROR';
export const UI_START_LOADING = 'UI_START_LOADING';
export const UI_FINISH_LOADING = 'UI_FINISH_LOADING';
export const UI_SHOW_BOOKING_BAR = 'UI_SHOW_BOOKING_BAR';
export const UI_HIDE_BOOKING_BAR = 'UI_HIDE_BOOKING_BAR';
export const UI_SHOW_NAV_BAR = 'UI_SHOW_NAV_BAR';
export const UI_HIDE_NAV_BAR = 'UI_HIDE_NAV_BAR';
export const UI_SHOW_SAVE_ON_MODAL = 'UI_SHOW_SAVE_ON_MODAL';
export const UI_HIDE_SAVE_ON_MODAL = 'UI_HIDE_SAVE_ON_MODAL';
export const UI_SET_TITLE = 'UI_SET_TITLE';