import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {toast} from 'react-toastify';
import Main from './modules/main/Main';
import Login from './modules/login/Login';
import PrivateRouter from "./../src/routes/PrivateRouter";
import PublicRouter from "./../src/routes/PublicRouter";
import './App.scss';

toast.configure({
    autoClose: 3000,
    draggable: false,
    position: 'top-right',
    hideProgressBar: false,
    newestOnTop: true,
    closeOnClick: true,
    rtl: false,
    pauseOnVisibilityChange: true,
    pauseOnHover: true
});

const App = () => {

    return (
        <Router basename="/">
            <Switch>
                <PrivateRouter exact path="/" component={Main} />
                <PublicRouter exact path="/login" component={Login}/>

                <PublicRouter path="/" component={ Main }/>
            </Switch>
        </Router>
    );
};

export default App;
