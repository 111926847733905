import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ColumnDirective, ColumnsDirective,
    GridComponent, Grid, Inject, Page, Sort, Filter, Resize,
    Toolbar, ExcelExport, PdfExport
} from '@syncfusion/ej2-react-grids';
import HttpClient from './../../../services/httpClient';
import { Dropdown, Select } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { useTranslation } from "react-i18next";
import { finishLoading, setTitle, startLoading } from "./../../../store/actions/uiAction";
import { logReportService } from "./../../../services/logReportService";

import ComboHotelSala from "./../../../components/combo-hotel-sala/combo-hotel-sala";

const Personal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [locationData, setLocationData] = useState([]);
    const [salesRoomData, setSalesRoomData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [location, setLocation] = useState('');
    const [salesRoom, setSalesRoom] = useState('');
    const [status, setStatus] = useState('A');
    const [rowData, setRowData] = useState([]);
    const [NameSala, setNameSala] = useState([]);
    const [NameHotel, setNameHotel] = useState([]);
    const { idSaleRoom, idHotel } = useSelector(state => state.user.param);
    const user = useSelector(state => state.auth.currentUser);
    let grid = null;

    const options = [
        { key: 'excel', icon: 'edit', text: 'Export to excel', value: 'excel' },
        { key: 'pdf', icon: 'delete', text: 'Export to pdf', value: 'pdf' },
    ]

    // <Combos>

    const onChangeLocation = (event, data) => setLocation(data.value);
    const onChangeSalesRoom = (event, data) => setSalesRoom(data.value);
    const onChangeStatus = (event, data) => setStatus(data.value);

    const statusOptions = [
        { key: 0, text: 'Active', value: 'A' },
        { key: 1, text: 'Inactive', value: 'I' },
        { key: 2, text: 'All', value: 'X' },
    ]

    const getOptionData = (url) => {
        return new Promise((resolve, eject) => {
            getDataReport(url).then((response) => {
                if (response.status === 200) {
                    let obj = response.data;
                    let res = [];
                    for (let i in obj) {
                        let item = { key: obj[i].id, text: obj[i].name, value: obj[i].id };
                        res.push(item)
                    }

                    resolve(res);
                }
            })
        })

    }

    const getLocationData = () => {
        getOptionData('/catalog/locations')
            .then((response) => {
                setLocationData(response);
            })
    }

    const getSalesRoomData = () => {
        getOptionData('/catalog/salasvta')
            .then((response) => {
                setSalesRoomData(response);
            })
    }

    // </Combos>
    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const handleViewButton = () => {
        getManifestReport();
    }

    const getManifestReport = () => {

        getDataReport(`/catalog/getNameSalavtaId/${idSaleRoom}`).then((response) => {
            if (response.status === 200) {
                setNameSala(response.data);
            }
        })

        getDataReport(`/catalog/getNameHotelId/${idHotel}`).then((response) => {
            if (response.status === 200) {
                setNameHotel(response.data);
            }
        })

        getDataReport(`/reports/personal/${idSaleRoom}/${idHotel}/${status}`).then((response) => {
            if (response.status === 200) {
                setRowData(response.data);
                logReportService.add('Personal Report', user);
            }
        })
    }

    // Reporte
    const handleButtonOption = (opt) => {
        if (grid && opt.target.innerText === 'Export to excel') {
            export2Excel();
        } else {
            if (grid && opt.target.innerText === 'Export to pdf') {
                grid.pdfExport();
            }
        }
    }

    const export2Excel = () => {

        const excelExportProperties = {

            fileName: t('menusidebar.label.personal') + ".xlsx",
            theme: {
                caption: { fontName: 'Calibri', fontColor: '#666666', fontSize: 13 },
                header: { fontName: 'Calibri', fontColor: '#000000', backColor: '#3F97BF', fontSize: 13 },
            },
            header: {
                headerRows: 4,
                rows: [
                    { cells: [{ colSpan: 4, value: "LVC KCS " + t('menusidebar.label.personal'), style: { fontColor: '#000000', fontSize: 20, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "SALES ROOM: " + NameSala[0]["name"], style: { fontColor: '#000000', fontSize: 14, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "HOTELS: " + NameHotel[0]["name"], style: { fontColor: '#000000', fontSize: 14, hAlign: 'Left', bold: true, } }] },
                ]
            }
        }
        grid.excelExport(excelExportProperties);
    }

    const handleExport2Excel = () => {
        if (grid) {
            export2Excel();
        }
    }

    const handleToolbarClick = (args) => {
        if (grid && args.item.id === 'grid_excelexport') {
            export2Excel();
        } else {
            if (grid && args.item.id === 'grid_pdfexport') {
                grid.pdfExport();
            }
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns();
        }
    }
    // Fin Reporte

    useEffect(() => {
        getLocationData();
        getSalesRoomData();
        setStatusData(statusOptions);
        dispatch(setTitle(t('menusidebar.label.personal')));
    }, []);

    return (
        <div className="container-fluid">
            <ComboHotelSala />
            <div className="row  col-md-12">

                <div className="col-md-3 form-group ">

                    <div className="form-group pl-1">
                        <label className="col-md-12">Status:</label>
                        <Dropdown className="col-md-10" placeholder='Status' selection
                            options={statusData}
                            onChange={onChangeStatus}
                            value={status}
                        />
                    </div>
                </div>
                <div className="col-md-6 form-group">
                    <Button primary onClick={handleViewButton}>View Report</Button>
                    <Button positive onClick={handleExport2Excel}>Export Report</Button>
                    {/*<Button.Group className="col-md-3" color='orange'>
                        <Button onClick={handleExport2Excel}>Print Report</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={options}
                            onChange={handleButtonOption}
                            trigger={<></>}
                        />
                        </Button.Group>*/}

                </div>
            </div>
            <div>
                <GridComponent id="grid" height='100%' width='auto'
                    dataSource={rowData}
                    allowSorting={true}
                    allowResizing={true}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={false}
                    toolbarClick={handleToolbarClick}
                    dataBound={dataBound}
                    ref={g => grid = g}
                >
                    <Inject services={[Sort, Toolbar, Resize, ExcelExport, PdfExport]} />
                    <ColumnsDirective>
                        <ColumnDirective field='rownum' headerText="Num" minWidth='5' textAlign="Right" />
                        <ColumnDirective field='nocolaborador' headerText="#Emp" minWidth='5' textAlign="Right" />
                        <ColumnDirective field='nombre' headerText="Name" Width='40' textAlign="Left" AutoFit />
                        <ColumnDirective field='puesto' headerText="Job" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='salaVta' headerText="Sales Room" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='locacion' headerText="Location" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='departamento' headerText="Depto" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='fechaIngreso' headerText="Admission date" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='estatus' headerText="Status" minWidth='10' textAlign="Right" />
                    </ColumnsDirective>
                </GridComponent>
            </div>
        </div>
    );
}

export default Personal;
