import React, { useEffect, useState } from 'react';
import HttpClient from './../../../services/httpClient';
import { Dropdown } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { MultiSelectComponent, CheckBoxSelection, Inject as InjectDrop } from '@syncfusion/ej2-react-dropdowns';
import { useTranslation } from "react-i18next";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { finishLoading, setTitle, startLoading } from "./../../../store/actions/uiAction";
import {
    ColumnDirective, ColumnsDirective,
    GridComponent, Grid, Inject, Page, Sort, Filter, Resize,
    Toolbar, ExcelExport, PdfExport
} from '@syncfusion/ej2-react-grids';
import {
    Aggregate, ForeignKey, getForeignData, AggregateColumnDirective
    , AggregateColumnsDirective, AggregateDirective, AggregatesDirective
} from '@syncfusion/ej2-react-grids';
import { useDispatch, useSelector } from "react-redux";
import { logReportService } from "./../../../services/logReportService";
import ComboHotelSala from "./../../../components/combo-hotel-sala/combo-hotel-sala";

const GeneralArrival = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [segmentoData, setSegmentoData] = useState([]);
    const [estatusLeadsData, setEstatusLeadsData] = useState([]);


    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [rowData, setRowData] = useState([]);
    const [NameSala, setNameSala] = useState([]);
    const [NameHotel, setNameHotel] = useState([]);
    const [NamePrograma, setNamePrograma] = useState([]);
    const [NameEstatusLeads, setNameEstatusLeads] = useState([]);
    const user = useSelector(state => state.auth.currentUser);
    const { idSaleRoom, idHotel } = useSelector(state => state.user.param);
    let grid = null;
    let mulObj = null;
    let mulObjEstatus = null;

    const optionsButton = [
        { key: 'excel', icon: 'edit', text: 'Export to excel', value: 'excel' },
        { key: 'pdf', icon: 'delete', text: 'Export to pdf', value: 'pdf' },
    ]
    
    const pageOptions = {
        pageSize: 50, pageSizes: true
    };

    const onChangeDateStart = (event, data) => setDateStart(data.value);
    const onChangeDateEnd = (event, data) => setDateEnd(data.value);

    // <Combos>


    const checkFields = { text: 'Name', value: 'Code' };
    // </Combos>

    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const getOptionData = (url) => {
        return new Promise((resolve, eject) => {
            getData(url).then((response) => {
                if (response.status === 200) {
                    let obj = response.data;
                    let res = [];
                    for (let i in obj) {
                        let item = { Code: obj[i].id, Name: obj[i].name };
                        res.push(item)
                    }

                    resolve(res);
                }
            })
        })

    }

    const getSegmentoData = () => {
        getOptionData('/catalog/segmentos')
            .then((response) => {
                setSegmentoData(response);
            })
    }

    const getEstatusData = () => {
        getOptionData('/catalog/estatusleads')
            .then((response) => {
                setEstatusLeadsData(response);
            })
    }

    const getData = (url, data) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url, data)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const getReport = () => {

        let info = 0;
        const seg = (mulObj != null && mulObj.value) ? mulObj.value.map(String).toString() : "";
        const estatatusleads = (mulObjEstatus != null && mulObjEstatus.value) ? mulObjEstatus.value.map(String).toString() : "";
        const date1 = dateStart.toISOString().slice(0, 10).replace(/-/g, '');
        const date2 = dateEnd.toISOString().slice(0, 10).replace(/-/g, '');
        /*const parameter = {
            "dateStart": dateStart.toISOString().slice(0, 10).replace(/-/g,''),
            "dateEnd": dateEnd.toISOString().slice(0, 10).replace(/-/g,''),
            "exc": available,
            "idPrecal": info,
            "inv": invited,
            "seg": 
            "idSaleRoom": idSaleRoom,
            "idHotel": idHotel
        }*/
       
        getDataReport(`/catalog/getNameSalavtaId/${idSaleRoom}`).then((response) => {
            if (response.status === 200) {   
                setNameSala(response.data);
            }
        })
        
        getDataReport(`/catalog/getNameHotelId/${idHotel}`).then((response) => {
            if (response.status === 200) {   
                setNameHotel(response.data);
            }
        })

        getDataReport(`/catalog/getNameProgramaId/${seg}`).then((response) => {
            if (response.status === 200) {   
                setNamePrograma(response.data);
            }
        })

        getDataReport(`/catalog/getNameEstatusLeadId/${seg}`).then((response) => {
            if (response.status === 200) {   
                setNameEstatusLeads(response.data);
            }
        })

        getDataReport(`/reports/general-arrival/${date1}/${date2}/${idSaleRoom}/${idHotel}/${seg}/${estatatusleads}`)
            .then((response) => {
                if (response.status === 200) {
                    setRowData(response.data);
                    logReportService.add('General Arrival Report', user);
                }
            })
            .catch((error) => {
                alert(error.response.data);
            });
    }

    const handleViewButton = () => {
        getReport();
    }

    // Reporte
    const handleButtonOption = (opt) => {
        if (grid && opt.target.innerText === 'Export to excel') {
            export2Excel();
        } else {
            if (grid && opt.target.innerText === 'Export to pdf') {
                grid.pdfExport();
            }
        }
    }

    const export2Excel = () => {
        const date1 = dateStart.toDateString();
        const date2 = dateEnd.toDateString();
        const excelExportProperties = {
            fileName: t('menusidebar.label.general-arrival') + ".xlsx",
            theme: {
                caption: { fontName: 'Calibri', fontColor: '#666666', fontSize: 13 },
                header: { fontName: 'Calibri', fontColor: '#000000', backColor: '#3F97BF', fontSize: 13 },
            },
            header: {
                headerRows: 7,
                rows: [
                    { cells: [{ colSpan: 4, value: "LVC KCS "+ t('menusidebar.label.general-arrival'), style: { fontColor: '#000000', fontSize: 16, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "SALES ROOM: "+NameSala[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "HOTELS: "+NameHotel[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "PROGRAMS: "+NamePrograma[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "STATUS LEADS: "+NameEstatusLeads[0]["name"], style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                  
                    { cells: [{ colSpan: 4, value: date1 + " - " + date2, style: { fontColor: '#000000', fontSize: 13, hAlign: 'Left', bold: true, } }] },
                ]
            }
        }
        grid.excelExport(excelExportProperties);
    }

    const handleExport2Excel = () => {
        if (grid) {
            export2Excel();
        }
    }

    const handleToolbarClick = (args) => {
        if (grid && args.item.id === 'grid_excelexport') {
            export2Excel();
        } else {
            if (grid && args.item.id === 'grid_pdfexport') {
                grid.pdfExport();
            }
        }
    }

    const footerSum = (props) => {
        return (<span>{props.Sum}</span>);
    }

    function customAggregateFnSumGrupo() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            gridData[i]['grupo'] == 'SI' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return totalSum1;
    }
    function customAggregateFnSumInfo() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            gridData[i]['info'] == 'SI' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return totalSum1;
    }
    function customAggregateFnSumInv() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            gridData[i]['inv'] == 'SI' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return totalSum1;
    }
    function customAggregateFnSumShow() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            gridData[i]['sh'] == 'SI' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return totalSum1;
    }

    function customAggregateFnSumSocios() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {

            gridData[i]['socios'] == 'SI' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return totalSum1;

    }

    function customAggregateFnSumQPrecal() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {

            gridData[i]['q'] == 'SI' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return totalSum1;

    }

    function customAggregateFnSumNQPrecal() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            gridData[i]['nq'] == 'SI' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return totalSum1;
    }

    function customAggregateFnSumCTPrecal() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            gridData[i]['ct'] == 'SI' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return totalSum1;
    }

    function customAggregateFnSumQCal() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            gridData[i]['qcal'] == 'SI' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return totalSum1;
    }
    function customAggregateFnSumNQCal() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            gridData[i]['nqcal'] == 'SI' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return totalSum1;
    }
    function customAggregateFnSumCTCal() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            gridData[i]['ctcal'] == 'SI' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return totalSum1;
    }
    function customAggregateFnSumSale() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            gridData[i]['sale'] == 'SI' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return totalSum1;
    }


    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns();
        }
    }
    // Fin Reporte

    useEffect(() => {
        getSegmentoData();
        getEstatusData();

        dispatch(setTitle(t('menusidebar.label.general-arrival')));
    }, []);

    return (
        <div className="container-fluid">
            <ComboHotelSala />
            <div className="row col-md-12">
                <div className="col-md-6 form-group">
                    <label >Date start:</label>
                    <SemanticDatepicker name="dateStart" value={dateStart} onChange={onChangeDateStart} />

                </div>
                <div className="col-md-6  form-group ">

                    <label >Date end:</label>
                    <SemanticDatepicker name="dateEnd" value={dateEnd} onChange={onChangeDateEnd} />

                </div>
                <div className="col-md-6 form-group">
                    <label >Programs:</label>
                    <MultiSelectComponent id="checkbox" ref={(scope) => { mulObj = scope; }} dataSource={segmentoData}
                        fields={checkFields} placeholder="Select segments" mode="CheckBox" showSelectAll={true}
                        showDropDownIcon={true} filterBarPlaceholder="Search segments" popupHeight="350px"
                    >
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </div>
                <div className="col-md-6 form-group">
                    <label >Status leads:</label>
                    <MultiSelectComponent id="checkbox" ref={(scope) => { mulObjEstatus = scope; }} dataSource={estatusLeadsData}
                        fields={checkFields} placeholder="Select estatus" mode="CheckBox" showSelectAll={true}
                        showDropDownIcon={true} filterBarPlaceholder="Search estatus" popupHeight="350px"
                    >
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </div>
                <div className="col-md-6 form-group">
                    <Button primary onClick={handleViewButton}>View Report</Button>
                    <Button positive onClick={handleExport2Excel}>Export Report</Button>
                    {/*<Button.Group className="col-md-3" color='orange'>
                        <Button onClick={handleExport2Excel}>Print Report</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={options}
                            onChange={handleButtonOption}
                            trigger={<></>}
                        />
                        </Button.Group>*/}
                </div>

            </div>
            <div>
                <GridComponent id="grid" height='100%' width='auto'
                    dataSource={rowData}
                    allowSorting={true}
                    allowResizing={true}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={false}
                    toolbarClick={handleToolbarClick}
                    dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={pageOptions}
                    ref={g => grid = g}
                >
                    <Inject services={[Sort, Toolbar, Resize, ExcelExport, PdfExport, Aggregate,Page]} />
                    <ColumnsDirective>
                        <ColumnDirective field='rownum' headerText="Num" minWidth='10' textAlign="left" />
                        <ColumnDirective field='estatus' headerText="Estatus" minWidth='10' textAlign="Right" />
                        <ColumnDirective field='razonnotrabajable' headerText="Razon" minWidth='10' textAlign="Right" />
                        <ColumnDirective field='folio' headerText="Lead" minWidth='10' textAlign="Right" />
                        <ColumnDirective field='hotel' headerText="Hotel" minWidth='10' textAlign="left" />
                        <ColumnDirective field='room' headerText="Room" minWidth='10' textAlign="left" />
                        <ColumnDirective field='name' headerText="Name" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='paxA' headerText="Pax A" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='paxN' headerText="Pax N" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='checkIn' headerText="Check-in" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='checkOut' headerText="Check-out" minWidth='10' textAlign="left" />
                        <ColumnDirective field='country' headerText="Country" minWidth='10' textAlign="left" />
                        <ColumnDirective field='agency' headerText="Agency" minWidth='10' textAlign="left" />
                        <ColumnDirective field='info' headerText="Info" minWidth='10' textAlign="Right" />
                        <ColumnDirective field='inv' headerText="Inv" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='sh' headerText="Sh" minWidth='10' textAlign="left" />
                        <ColumnDirective field='socios' headerText="Socios" minWidth='10' textAlign="left" />
                        <ColumnDirective field='grupo' headerText="Grupos" minWidth='10' textAlign="left" />
                        <ColumnDirective field='q' headerText="QPrecal" minWidth='10' textAlign="left" format="C2" />
                        <ColumnDirective field='nq' headerText="NQPrecal" minWidth='10' textAlign="left" format="C2" />
                        <ColumnDirective field='ct' headerText="CTPrecal" minWidth='10' textAlign="left" format="C2" />
                        <ColumnDirective field='qcal' headerText="QCal" minWidth='10' textAlign="left" format="C2" />
                        <ColumnDirective field='nqcal' headerText="NQCal" minWidth='10' textAlign="left" format="C2" />
                        <ColumnDirective field='ctcal' headerText="CTCal" minWidth='10' textAlign="left" format="C2" />
                        <ColumnDirective field='sale' headerText="Sale" minWidth='10' textAlign="left" />
                        <ColumnDirective field='contract' headerText="Membresia" width='10' textAlign="left" />
                        <ColumnDirective field='memb' headerText="TipoMemb" minWidth='10' textAlign="left" />
                        <ColumnDirective field='amount' headerText="Amount" minWidth='10' textAlign="Left" format="C2" />
                        <ColumnDirective field='pr_01' headerText="CC1" minWidth='10' textAlign="left" />
                        <ColumnDirective field='pr_02' headerText="CC2" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='comments' headerText="Comment" minWidth='30' textAlign="left" />
                    </ColumnsDirective>
                    <AggregatesDirective>
                        <AggregateDirective>
                            <AggregateColumnsDirective>
                                <AggregateColumnDirective field='paxA' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='paxN' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='info' type='Custom' customAggregate={customAggregateFnSumInfo} />
                                <AggregateColumnDirective field='inv' type='Custom' customAggregate={customAggregateFnSumInv} />
                                <AggregateColumnDirective field='sh' type='Custom' customAggregate={customAggregateFnSumShow} />
                                <AggregateColumnDirective field='socios' type='Custom' customAggregate={customAggregateFnSumSocios} />
                                <AggregateColumnDirective field='grupo' type='Custom' customAggregate={customAggregateFnSumGrupo} />
                                <AggregateColumnDirective field='q' type='Custom' customAggregate={customAggregateFnSumQPrecal} />
                                <AggregateColumnDirective field='nq' type='Custom' customAggregate={customAggregateFnSumNQPrecal} />
                                <AggregateColumnDirective field='ct' type='Custom' customAggregate={customAggregateFnSumCTPrecal} />
                                <AggregateColumnDirective field='qcal' type='Custom' customAggregate={customAggregateFnSumQCal} />
                                <AggregateColumnDirective field='nqcal' type='Custom' customAggregate={customAggregateFnSumNQCal} />
                                <AggregateColumnDirective field='ctcal' type='Custom' customAggregate={customAggregateFnSumCTCal} />
                                <AggregateColumnDirective field='sale' type='Custom' customAggregate={customAggregateFnSumSale} />
                                <AggregateColumnDirective field='amount' type='Sum' format="C2" customAggregate={footerSum} />
                            </AggregateColumnsDirective>
                        </AggregateDirective>
                    </AggregatesDirective>
                </GridComponent>
            </div>
        </div>
    );
}

export default GeneralArrival;
