import * as actionTypes from './../actions';

const initialState = {
    loading: false,
    msgError: null,
    msgTitle: ''
}

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UI_SET_ERROR:
            return {
                ...state,
                msgError: action.payload
            }
        case actionTypes.UI_REM_ERROR:
            return {
                ...state,
                msgError: null
            }
        case actionTypes.UI_START_LOADING:
            return {
                ...state,
                loading: true
            }
        case actionTypes.UI_FINISH_LOADING:
            return {
                ...state,
                loading: false
            }
        case actionTypes.UI_SET_TITLE:
            return  {
                ...state,
                msgTitle: action.payload
            }
        default:
            return state;
    }
}

export default uiReducer;