import React, {useEffect, useState} from 'react';
import SmallBox from '../components/small-box/SmallBox';
import {finishLoading, startLoading} from "./../store/actions/uiAction";
import HttpClient from "./../services/httpClient";
import {useDispatch} from "react-redux";

const Dashboard = () => {
    const dispatch = useDispatch();
    const [rowData, setRowData] = useState([{invitations:0, shows:0, sales:0, volume:0}]);

    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const getSummaryReport = () => {
        getDataReport(`/reports/summary`).then((response) => {
            if (response.status === 200) {
                setRowData(response.data);
            }
        })
    }

    useEffect(() => {
        getSummaryReport();
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={rowData[0].invitations}
                        title="Invitations"
                        type="info"
                        icon="ion-android-people"
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={rowData[0].shows}
                        title="Shows"
                        type="success"
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={rowData[0].sales}
                        title="Sales"
                        type="warning"
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={rowData[0].volume}
                        title="Volume"
                        type="danger"
                        navigateTo="/"
                    />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
