import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ColumnDirective, ColumnsDirective,
    GridComponent, Grid, Inject, Page, Sort, Filter, Resize,
    Toolbar, ExcelExport, PdfExport
} from '@syncfusion/ej2-react-grids';
import { Aggregate, ForeignKey, getForeignData, AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import HttpClient from './../../../services/httpClient';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Dropdown, Select } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { useTranslation } from "react-i18next";
import { finishLoading, setTitle, startLoading } from "./../../../store/actions/uiAction";
import { logReportService } from "./../../../services/logReportService";
import ComboHotelSala from "./../../../components/combo-hotel-sala/combo-hotel-sala";
import ComboAgencias from "./../../../components/combo-agencias/combo-agencias";





const ProductionAgency = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [rowData, setRowData] = useState([]);
    const user = useSelector(state => state.auth.currentUser);
    const { idSaleRoom, idHotel, idAgencia } = useSelector(state => state.user.param);
    let grid = null;

    const options = [
        { key: 'excel', icon: 'edit', text: 'Export to excel', value: 'excel' },
        { key: 'pdf', icon: 'delete', text: 'Export to pdf', value: 'pdf' },
    ]

    const onChangeDateStart = (event, data) => setDateStart(data.value);
    const onChangeDateEnd = (event, data) => setDateEnd(data.value);

    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const handleViewButton = () => {
        getManifestReport();
    }

    const getManifestReport = () => {
        const date1 = dateStart.toISOString().slice(0, 10).replace(/-/g, '');
        const date2 = dateEnd.toISOString().slice(0, 10).replace(/-/g, '');

        getDataReport(`/reports/agency-production/${date1}/${date2}/${idSaleRoom}/${idHotel}/${idAgencia}`).then((response) => {
            if (response.status === 200) {
                setRowData(response.data);
                logReportService.add('Production Agency', user);
            }
        })
    }

    // Reporte
    const handleButtonOption = (opt) => {
        if (grid && opt.target.innerText === 'Export to excel') {
            export2Excel();
        } else {
            if (grid && opt.target.innerText === 'Export to pdf') {
                grid.pdfExport();
            }
        }
    }

    const export2Excel = () => {
        const date1 = dateStart.toDateString();
        const date2 = dateEnd.toDateString();
        const excelExportProperties = {
            fileName:  t('menusidebar.label.countries-innssit')+".xlsx",
            theme: {
                caption: { fontName: 'Calibri', fontColor: '#666666', fontSize: 13 },
                header: { fontName: 'Calibri', fontColor: '#000000', backColor: '#3F97BF', fontSize: 13 },
            },
            header: {
                headerRows: 4,
                rows: [
                    { cells: [{ colSpan: 4, value: "LVC KCS", style: { fontColor: '#000000', fontSize: 20, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: t('menusidebar.label.countries-innssit'), style: { fontColor: '#000000', fontSize: 16, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value:  date1+" - "+date2, style: { fontColor: '#000000', fontSize: 16, hAlign: 'Left', bold: true, } }] },
                ]
            }
        }
        grid.excelExport(excelExportProperties);
    }

    const handleExport2Excel = () => {
        if (grid) {
            export2Excel();
        }
    }


    const handleToolbarClick = (args) => {
        if (grid && args.item.id === 'grid_excelexport') {
            export2Excel();
        } else {
            if (grid && args.item.id === 'grid_pdfexport') {
                grid.pdfExport();
            }
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns();
        }
    }

    function footerSum(props) {
        return (<span>{props.Sum}</span>);

    }

    const footerArrivalSum = (props) => {
        return (<span>{props.Sum}</span>);
    }

    const footeAvailsSum = (props) => {
        return (<span>{props.Sum}</span>);
    }

    function footerAverage(props) {
        return (<span>{props.Average}</span>);
    }

    function custom(props) {
        return (<span>{props.Custom}</span>);
    }


    function customAggregateFn() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {

            totalSum1 = totalSum1 + gridData[i]['avails'];
            totalSum2 = totalSum2 + gridData[i]['arrivals'];
        }
        let average = 0;

        totalSum2 == 0 ? average = totalSum2 : average = totalSum1 / totalSum2;

        // return the calculated value

        return average;

    }

    function customAggregateFnBk() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource

        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset

        for (var i = 0; i < gridData.length; i++) {

            totalSum1 = totalSum1 + gridData[i]['bk'];
            totalSum2 = totalSum2 + gridData[i]['avails'];
        }

        let average = 0;

        totalSum2 == 0 ? average = totalSum2 : average = totalSum1 / totalSum2;
        // return the calculated value
        return average;

    }

    function customAggregateFnSh() {
        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['sh'];
            totalSum2 = totalSum2 + gridData[i]['bk'];
        }

        let average = 0;

        totalSum2 == 0 ? average = totalSum2 : average = totalSum1 / totalSum2;
        // return the calculated value

        return average;

    }

    function customAggregateFnCl() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['cancelled'];
            totalSum2 = totalSum2 + gridData[i]['totalSales'];
        }

        let average = 0;
        totalSum2 == 0 ? average = totalSum2 : average = totalSum1 / totalSum2;
        // return the calculated value

        return average;

    }

    function customAggregateFnEff() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['totalVolume'];
            totalSum2 = totalSum2 + gridData[i]['tShows'];
        }

        let eff = 0;

        totalSum2 == 0 ? eff = 0 : eff = totalSum1 / totalSum2;


        // return the calculated value

        return eff;

    }

    function customAggregateFnClo() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['totalSales'];
            totalSum2 = totalSum2 + gridData[i]['tShows'];
        }

        let closingPercent = 0;

        totalSum2 == 0 ? closingPercent = 0 : closingPercent = totalSum1 / totalSum2;


        // return the calculated value

        return closingPercent;

    }

    function customAggregateFnAvg() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            totalSum1 = totalSum1 + gridData[i]['totalVolume'];
            totalSum2 = totalSum2 + gridData[i]['totalSales'];
        }

        let avg = 0;

        totalSum2 == 0 ? avg = 0 : avg = totalSum1 / totalSum2;

        // return the calculated value

        return avg;

    }


    // Fin Reporte

    useEffect(() => {
        dispatch(setTitle(t('menusidebar.label.countries-innsist')));
    }, [])

    return (
        <div className="container-fluid">
      
            <div className="row  col-md-12">
                
                <div className="col-md-6  form-group">

                    <Button primary onClick={handleViewButton}>View Report</Button>
                    <Button positive onClick={handleExport2Excel}>Export Report</Button>
                    {/*<Button.Group className="col-md-3" color='orange'>
                        <Button onClick={handleExport2Excel}>Print Report</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={options}
                            onChange={handleButtonOption}
                            trigger={<></>}
                        />
                        </Button.Group>*/}


                </div>
            </div>
            <div>
                <GridComponent id="grid" height='100%' width='auto'
                    dataSource={rowData}
                    allowSorting={true}
                    allowResizing={true}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={false}
                    toolbarClick={handleToolbarClick}
                    dataBound={dataBound}
                    ref={g => grid = g}
                >
                    <Inject services={[Sort, Toolbar, Resize, ExcelExport, PdfExport, Aggregate]} />
                    <ColumnsDirective>
                        <ColumnDirective field='rownum' headerText="Num" minWidth='5' textAlign="Right" />
                        <ColumnDirective field='agency' headerText="Agency" Width='40' textAlign="Left" AutoFit />
                        <ColumnDirective field='arrivals' headerText="Arrivals" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='avails' headerText="Avails" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='aper' headerText="A%" minWidth='20' textAlign="Left" format="P2" />
                        <ColumnDirective field='info' headerText="Info" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='bk' headerText="Bk" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='bkFactor' headerText="Bk%" minWidth='20' textAlign="Left" format="P2" />
                        <ColumnDirective field='sh' headerText="Sh" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='shFactor' headerText="Sh%" minWidth='20' textAlign="Left" format="P2" />
                        <ColumnDirective field='dir' headerText="Dir" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='tShows' headerText="TShow" minWidth='10' textAlign="Right" />
                        <ColumnDirective field='qs' headerText="Qs" minWidth='20' textAlign="Right" />
                        <ColumnDirective field='nQs' headerText="NQs" minWidth='20' textAlign="Right" />
                        <ColumnDirective field='ct' headerText="CTs" width='20' textAlign="Right" format="N2" />
                        <ColumnDirective field='sales' headerText="Sales" minWidth='20' textAlign="Right" />
                        <ColumnDirective field='processable' headerText="Processable" minWidth='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='oop' headerText="OOP" minWidth='20' textAlign="Right" />
                        <ColumnDirective field='oopVolume' headerText="#" minWidth='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='cancelled' headerText="Cancelled" minWidth='20' textAlign="Right" />
                        <ColumnDirective field='cancelledVolume' headerText="#" minWidth='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='cancelPercent' headerText="C%" minWidth='20' format="P2" textAlign="Right" />
                        <ColumnDirective field='totalSales' headerText="Total Sales" minWidth='20' textAlign="Right" />
                        <ColumnDirective field='totalVolume' headerText="Total Volume" minWidth='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='eff' headerText="Eff" minWidth='25' textAlign="Right" format="C2" />
                        <ColumnDirective field='closingPercent' headerText="CL%" minWidth='20' textAlign="Right" format="P2" />
                        <ColumnDirective field='avg' headerText="AVG" minWidth='20' textAlign="Right" format="C2" />
                        <ColumnDirective field='pending' headerText="Pending" minWidth='20' textAlign="Right" />
                        <ColumnDirective field='pendingVolume' headerText="#" minWidth='20' textAlign="Right" format="C2" />
                    </ColumnsDirective>
                    <AggregatesDirective>
                        <AggregateDirective>
                            <AggregateColumnsDirective>
                                <AggregateColumnDirective field='arrivals' type='Sum' customAggregate={footerArrivalSum} />
                                <AggregateColumnDirective field='avails' type='Sum' customAggregate={footeAvailsSum} />
                                <AggregateColumnDirective field='aper' type='Custom' format="P2" customAggregate={customAggregateFn} />
                                <AggregateColumnDirective field='info' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='bk' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='bkFactor' type='Custom' format='P2' customAggregate={customAggregateFnBk} />
                                <AggregateColumnDirective field='sh' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='shFactor' type='Custom' format='P2' customAggregate={customAggregateFnSh} />
                                <AggregateColumnDirective field='dir' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='tShows' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='qs' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='nQs' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='ct' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='sales' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='processable' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='oop' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='oopVolume' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='cancelled' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='cancelledVolume' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='cancelPercent' type='Custom' format='P2' customAggregate={customAggregateFnCl} />
                                <AggregateColumnDirective field='totalSales' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='totalVolume' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='eff' type='Custom' format="C2" customAggregate={customAggregateFnEff} />
                                <AggregateColumnDirective field='closingPercent' type='Custom' format='P2' customAggregate={customAggregateFnClo} />
                                <AggregateColumnDirective field='avg' type='Custom' format="C2" customAggregate={customAggregateFnAvg} />
                                <AggregateColumnDirective field='pending' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='pendingVolume' type='Sum' format="C2" customAggregate={footerSum} />
                            </AggregateColumnsDirective>
                        </AggregateDirective>
                    </AggregatesDirective>
                </GridComponent>
            </div>
        </div>
    );
}

export default ProductionAgency;
