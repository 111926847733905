import React, {useEffect, useState} from 'react';
import { Dropdown } from 'semantic-ui-react'
import {finishLoading, startLoading} from "./../../store/actions/uiAction";
import HttpClient from "./../../services/httpClient";
import {useDispatch} from "react-redux";
import {setIdPais} from "./../../store/actions/dataAction";

const ComboPaises = () => {
    const dispatch = useDispatch();
    const [PaisesData, setPaisesData] = useState([]);
    const [Pais, setPais] = useState(0);
    
    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const getOptionData = (url) => {
        return new Promise((resolve, eject) => {
            getDataReport(url).then((response) => {
                if (response.status === 200) {
                    let obj = response.data;
                    let res = [];
                    for(let i in obj) {
                        let item = {key: obj[i].id, text: obj[i].name, value: obj[i].id};
                        res.push(item)
                    }

                    resolve(res);
                }
            })
        })
    }

    const getPaisesData = () => {
        getOptionData('/catalog/paises')
            .then((response) => {
                setPaisesData(response);
            })
    }

    const handlePaisChange = (e, {value}) => {
        setPais(value);     
        dispatch(setIdPais(value));
       
    } 

    useEffect(() => {
        getPaisesData();
    }, []);

    return (
        <>
            <div className="row col-md-12">
                    <div className="form-group col-md-6">
                        <label>Countries:</label>
                        <Dropdown className="form-control" placeholder='ALL COUNTRIES' selection clearable search
                                  options={PaisesData}
                                  onChange={handlePaisChange}
                                  value={Pais}
                        />
                    </div>
            </div>
        </>
    );
};

export default ComboPaises;
