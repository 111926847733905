import * as actionTypes from './../actions';

export const setError = ( err ) => ({
    type: actionTypes.UI_SET_ERROR,
    payload: err
});

export const removeError = ( err ) => ({
    type: actionTypes.UI_REM_ERROR,
});

export const startLoading = () => ({
    type: actionTypes.UI_START_LOADING
});

export const finishLoading = () => ({
    type: actionTypes.UI_FINISH_LOADING
});

export const setTitle = (title) => ({
    type: actionTypes.UI_SET_TITLE,
    payload: title
});