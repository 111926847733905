import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { ColumnDirective, ColumnsDirective,
    GridComponent, Grid, Inject, Page, Sort, Filter, Resize,
    Toolbar, ExcelExport, PdfExport } from '@syncfusion/ej2-react-grids';
import HttpClient from './../../../services/httpClient';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Dropdown, Select } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {useTranslation} from "react-i18next";
import {finishLoading, setTitle, startLoading} from "./../../../store/actions/uiAction";
import {logReportService} from "./../../../services/logReportService";
import ComboHotelSala from "./../../../components/combo-hotel-sala/combo-hotel-sala";

const GiftReport = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [rowData, setRowData] = useState([]);
    const user = useSelector(state => state.auth.currentUser);
    const {idSaleRoom, idHotel} = useSelector(state => state.user.param);
    let grid = null;

    const options = [
        { key: 'excel', icon: 'edit', text: 'Export to excel', value: 'excel' },
        { key: 'pdf', icon: 'delete', text: 'Export to pdf', value: 'pdf' },
    ]

    const onChangeDateStart = (event, data) => setDateStart(data.value);
    const onChangeDateEnd = (event, data) => setDateEnd(data.value);

    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const handleViewButton = () => {
        getManifestReport();
    }

    const getManifestReport = () => {

        const date1 = dateStart.toISOString().slice(0, 10).replace(/-/g,'');;
        const date2 = dateEnd.toISOString().slice(0, 10).replace(/-/g,'');;

        getDataReport(`/reports/gift-manifest/${date1}/${date2}/${idSaleRoom}/${idHotel}`).then((response) => {
            if (response.status === 200) {
                setRowData(response.data);
                logReportService.add('Gift Report', user);
            }
        })
    }

    // Reporte
    const handleButtonOption = (opt) => {
        if (grid && opt.target.innerText === 'Export to excel') {
            export2Excel();
        } else {
            if (grid && opt.target.innerText === 'Export to pdf') {
                grid.pdfExport();
            }
        }
    }

    const export2Excel = () => {
        const excelExportProperties = {
            theme: {
                caption: { fontName: 'Segoe UI', fontColor: '#666666'},
                header: { fontName: 'Segoe UI', fontColor: '#000000', backColor: '#ffd966' },
            },
            header: {
                headerRows: 3,
                rows: [
                    { cells: [{ colSpan: 4, value: "LVC Travel Your Way", style: { fontColor: '#305496', fontSize: 20, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: t('menusidebar.label.gift-report'), style: { fontColor: '#C67878', fontSize: 15, hAlign: 'Left', bold: true, } }] },
                ]
            }
        }
        grid.excelExport(excelExportProperties);
    }

    const handleExport2Excel = () => {
        if (grid) {
            export2Excel();
        }
    }

    const handleToolbarClick = (args) => {
        if (grid && args.item.id === 'grid_excelexport') {
            export2Excel();
        } else {
            if (grid && args.item.id === 'grid_pdfexport') {
                grid.pdfExport();
            }
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns();
        }
    }
    // Fin Reporte

    useEffect(() => {
        dispatch(setTitle(t('menusidebar.label.gift-report')));
    }, []);

    return (
        <div className="container-fluid">
            <ComboHotelSala />
            <div className="row pb-2">
                <div className="col-md-2">
                    <div className="form-group">
                        <label className="pl-2">Date start:</label>
                        <SemanticDatepicker className="col-md-9" name="dateStart" value={dateStart} onChange={onChangeDateStart} />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        <label className="pl-2">Date end:</label>
                        <SemanticDatepicker className="col-md-9" name="dateEnd" value={dateEnd} onChange={onChangeDateEnd} />
                    </div>
                </div>
                <div className="col-md-3 pt-4">
                    <Button className="col-md-5 mb-1" primary onClick={handleViewButton}>View Report</Button>
                    <Button.Group className="col-md-5 mb-1" color='orange'>
                        <Button onClick={handleExport2Excel}>Print Report</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={options}
                            onChange={handleButtonOption}
                            trigger={<></>}
                        />
                    </Button.Group>
                </div>
            </div>
            <div>
                <GridComponent id="grid" height='100%' width='auto'
                               dataSource={rowData}
                               allowSorting={true}
                               allowResizing={true}
                               allowExcelExport={true}
                               allowPdfExport={true}
                               toolbar={['Print','ExcelExport','PdfExport']}
                               toolbarClick={handleToolbarClick}
                               dataBound={dataBound}
                               ref={g => grid = g }
                >
                    <Inject services={[Sort, Toolbar, Resize, ExcelExport, PdfExport]} />
                    <ColumnsDirective>
                        <ColumnDirective field='guestID' headerText="GuestID" width='10' textAlign="Right"/>
                        <ColumnDirective field='date' headerText="Date" width='20' textAlign="Right"/>
                        <ColumnDirective field='guest' headerText="Guest" width='10' textAlign="Left"/>
                        <ColumnDirective field='location' headerText="Location" width='10' textAlign="Left"/>
                        <ColumnDirective field='hostess' headerText="Hostess" width='20' textAlign="Left"/>
                        <ColumnDirective field='gift' headerText="Gift" width='10' textAlign="Left"/>
                        <ColumnDirective field='qty' headerText="Qty" width='10' textAlign="Right"/>
                        <ColumnDirective field='cost' headerText="Cost" width='20' textAlign="Right" format="C2"/>
                    </ColumnsDirective>
                </GridComponent>
            </div>
        </div>
    );
}

export default GiftReport;
