import React from 'react';
import {Redirect, Route} from 'react-router-dom';

const PublicRouter = ({children, ...rest}) => {
    const isAuthenticated = localStorage.getItem('token_security');

    return (
        <Route
            {...rest}
            render={({location}) =>
                isAuthenticated ? (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: {from: location}
                        }}
                    />
                ) : (
                    children
                )
            }
        />
    );
};

export default PublicRouter;