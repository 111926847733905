import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ColumnDirective, ColumnsDirective,
    GridComponent, Grid, Inject, Page, Sort, Filter, Resize,
    Toolbar, ExcelExport, PdfExport
} from '@syncfusion/ej2-react-grids';

import HttpClient from './../../../services/httpClient';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Dropdown, Select } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { useTranslation } from "react-i18next";
import { finishLoading, setTitle, startLoading } from "./../../../store/actions/uiAction";
import { logReportService } from "./../../../services/logReportService";





const Reservations = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [rowData, setRowData] = useState([]);
    const user = useSelector(state => state.auth.currentUser);
    const { idSaleRoom, idHotel, idAgencia } = useSelector(state => state.user.param);
    let grid = null;

    const options = [
        { key: 'excel', icon: 'edit', text: 'Export to excel', value: 'excel' },
        { key: 'pdf', icon: 'delete', text: 'Export to pdf', value: 'pdf' },
    ]

    const onChangeDateStart = (event, data) => setDateStart(data.value);
    const onChangeDateEnd = (event, data) => setDateEnd(data.value);

    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const handleViewButton = () => {
        getReservationsReport();
    }

    const getReservationsReport = () => {
        
        getDataReport(`/reservations`).then((response) => {
            if (response.status === 200) {
                setRowData(response.data);
                logReportService.add('Reservations innsist', user);
            }
        })
    }

    const onLoad = () => {
        let gridElement = document.getElementById('grid');
        if (gridElement && gridElement.ej2_instances[0]) {
            let gridInstance = gridElement.ej2_instances[0];
            /** height of the each row */
            const rowHeight = gridInstance.getRowHeight();
            /** Grid height */
            const gridHeight = gridInstance.height;
            /** initial page size */
            const pageSize = gridInstance.pageSettings.pageSize;
            /** new page size is obtained here */
            const pageResize = (gridHeight - (pageSize * rowHeight)) / rowHeight;
            gridInstance.pageSettings.pageSize = pageSize + Math.round(pageResize);
        }
    };

    // Reporte
    const handleButtonOption = (opt) => {
        if (grid && opt.target.innerText === 'Export to excel') {
            export2Excel();
        } else {
            if (grid && opt.target.innerText === 'Export to pdf') {
                grid.pdfExport();
            }
        }
    }

    const export2Excel = () => {
    
        const excelExportProperties = {
            fileName:  t('menusidebar.label.reservations-innsist')+".xlsx",
            theme: {
                caption: { fontName: 'Calibri', fontColor: '#666666', fontSize: 13 },
                header: { fontName: 'Calibri', fontColor: '#000000', backColor: '#3F97BF', fontSize: 13 },
            },
            header: {
                headerRows: 4,
                rows: [
                    { cells: [{ colSpan: 4, value: "LVC KCS", style: { fontColor: '#000000', fontSize: 20, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: t('menusidebar.label.reservations-innsist'), style: { fontColor: '#000000', fontSize: 16, hAlign: 'Left', bold: true, } }] },
                ]
            }
        }
        grid.excelExport(excelExportProperties);
    }

    const handleExport2Excel = () => {
        if (grid) {
            export2Excel();
        }
    }


    const handleToolbarClick = (args) => {
        if (grid && args.item.id === 'grid_excelexport') {
            export2Excel();
        } else {
            if (grid && args.item.id === 'grid_pdfexport') {
                grid.pdfExport();
            }
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns();
        }
    }

    const pageOptions = {
        pageSize: 50, pageSizes: true
    };

    const FilterOptions = {
        type: 'Menu'
    };
 

    // Fin Reporte

    useEffect(() => {
        dispatch(setTitle(t('menusidebar.label.reservations-innsist')));
    }, [])

    return (
        <div className="container-fluid">
          
            <div className="row  col-md-12">
             
                <div className="col-md-6  form-group">

                    <Button primary onClick={handleViewButton}>View Report</Button>
                    <Button positive onClick={handleExport2Excel}>Export Report</Button>
                    {/*<Button.Group className="col-md-3" color='orange'>
                        <Button onClick={handleExport2Excel}>Print Report</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={options}
                            onChange={handleButtonOption}
                            trigger={<></>}
                        />
                        </Button.Group>*/}


                </div>
            </div>
            <div>
                <GridComponent id="grid" height='100%' width='auto'
                    dataSource={rowData}
                    allowSorting={true}
                    allowResizing={true}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    allowFiltering={true}
                    filterSettings={FilterOptions}
                    allowPaging={true}  
                    pageSettings={pageOptions}
                   
                    toolbar={false}
                    toolbarClick={handleToolbarClick}
                    dataBound={dataBound}
                    ref={g => grid = g}
                >
                    <Inject services={[Sort, Toolbar, Resize, ExcelExport, PdfExport,Filter,Page]} />
                    <ColumnsDirective>
                        <ColumnDirective field='idresvation' headerText="Idresvation" minWidth='5' textAlign="Right" />
                        <ColumnDirective field='hotel' headerText="Hotel" Width='40' textAlign="Left" AutoFit />
                        <ColumnDirective field='cuenta' headerText="Cuenta" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='crscode' headerText="Crscode" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='status' headerText="Status" minWidth='20' textAlign="Left"/>
                        <ColumnDirective field='nameclient' headerText="Client" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='adultsqty' headerText="AdultsQty" minWidth='20' textAlign="Left"/>
                        <ColumnDirective field='childrenqty' headerText="Childrenqty" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='arrivaldate' headerText="Arrival date" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='departuredate' headerText="Departure date" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='state' headerText="State" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='country' headerText="Country" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='agencyinfo' headerText="Agency" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='guesttype' headerText="Plan A" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='mealplan' headerText="Meal plan" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='roomtypecode' headerText="Room type code" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='roomid' headerText="Room id" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='importado' headerText="importado" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='fechaimportacion' headerText="fechaimportacion" minWidth='10' textAlign="Left" />
                       
                    </ColumnsDirective>
                  
                </GridComponent>
            </div>
        </div>
    );
}

export default Reservations;
