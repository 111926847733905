import React, {useState, useEffect} from 'react';
import HttpClient from './../../../services/httpClient';
import { Button } from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import ReportViewer from "./../../../components/ReportViewer/ReportViewer";
import {finishLoading, setTitle, startLoading} from "./../../../store/actions/uiAction";
import {useDispatch, useSelector} from "react-redux";
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import ComboHotelSala from "./../../../components/combo-hotel-sala/combo-hotel-sala";

const GraphicProduction = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [rowData, setRowData] = useState([]);
    const user = useSelector(state => state.auth.currentUser);
    const {idSaleRoom, idHotel} = useSelector(state => state.user.param);

    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const getData = (url) => {
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    eject(error);
                })
        });
    }

    const getReport = () => {
        getData(`/reports/graphic-production/${idSaleRoom}/${idHotel}`).then((response) => {
            if (response.status === 200) {
                setRowData(response.data);
            }
        })
    }

    const handleViewButton = () => {
        getReport();
    }

    useEffect(() => {
        dispatch(setTitle(t('menusidebar.label.graphic-production')));
    }, []);

    return (
        <div className="container-fluid">
            <ComboHotelSala />
            <div className="row col-md-12">
                <div className="col-md-12 pb-2">
                        <Button className="col-md-2 float-right mb-1" primary onClick={handleViewButton}>View Report</Button>
                </div>
            </div>
            <div>
                <ReportViewer reportSource="graphic-production.trdp"/>
            </div>
        </div>
    );
}

export default GraphicProduction;
