import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import axios from '../../utils/axios';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import Dashboard from './../../pages/Dashboard';
import Profile from './../../pages/profile/Profile';
import ReactLoading from 'react-loading';
import PreManifest from "./../../pages/reports/report-sales-room/PreManifest";
import Header from './header/Header';
import Footer from './footer/Footer';
import MenuSidebar from './menu-sidebar/MenuSidebar';
import PageLoading from '../../components/page-loading/PageLoading';
import * as ActionTypes from '../../store/actions';
import PRProduction from "./../../pages/reports/processor/PRProduction";
import ActiveMembership from "./../../pages/reports/general-payroll/ActiveMembership";
import GeneralArrival from "./../../pages/reports/general-payroll/GeneralArrival";
import Personal from "./../../pages/reports/general-payroll/Personal";
import PrintLog from "./../../pages/reports/general-payroll/PrintLog";
import GraphicProduction from "./../../pages/reports/processor/GraphicProduction";
import ProductionCountry from "./../../pages/reports/processor/ProductionCountry";
import UnavailableArrival from "./../../pages/reports/processor/UnavailableArrival";
import ContractTime from "./../../pages/reports/processor/ContractTime";
import AdpDueDateReport from "./../../pages/reports/report-sales-room/AdpDueDateReport";
import CancellationReport from "./../../pages/reports/report-sales-room/CancellationReport";
import ContractAnalyst from "./../../pages/reports/report-sales-room/ContractAnalyst";
import GiftLocationReport from "./../../pages/reports/report-sales-room/GiftLocationReport";
import SalesRoomStats from "./../../pages/reports/report-sales-room/SalesRoomStats";
import GiftReport from "./../../pages/reports/report-sales-room/GiftReport";
import ManifestReport from "./../../pages/reports/report-sales-room/ManifestReport";
import MembershipReport from "./../../pages/reports/report-sales-room/MembershipReport";
import PendingReport from "./../../pages/reports/report-sales-room/PendingReport";
import ProductionAgency from "./../../pages/reports/processor/ProductionAgency";
import SalesReport from "./.././../pages/reports/report-sales-room/SalesReport";
import GreetersReport from "./.././../pages/reports/report-sales-room/GreetersReport";
import Reservations from "./.././../pages/reports/service-innsist/Reservations";
import Countries from "./.././../pages/reports/service-innsist/Countries";

const Main = ({onUserLoad}) => {
    const {loading} = useSelector( state => state.ui || false );
    const [appLoadingState, updateAppLoading] = useState(false);
    const [menusidebarState, updateMenusidebarState] = useState({
        isMenuSidebarCollapsed: false
    });

    const toggleMenuSidebar = () => {
        updateMenusidebarState({
            isMenuSidebarCollapsed: !menusidebarState.isMenuSidebarCollapsed
        });
    };

    useEffect(() => {
        // updateAppLoading(true);
        // const fetchProfile = async () => {
        //     try {
        //         const response = await axios.get('/v1/users/profile');
        //         onUserLoad({...response.data});
        //         updateAppLoading(false);
        //     } catch (error) {
        //         updateAppLoading(false);
        //     }
        // };
        //fetchProfile();
        return () => {};
    }, [onUserLoad]);

    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');

    document.getElementById('root').className += ' sidebar-mini';

    if (menusidebarState.isMenuSidebarCollapsed) {
        document.getElementById('root').classList.add('sidebar-collapse');
        document.getElementById('root').classList.remove('sidebar-open');
    } else {
        document.getElementById('root').classList.add('sidebar-open');
        document.getElementById('root').classList.remove('sidebar-collapse');
    }

    let template;

    if (appLoadingState) {
        template = <PageLoading />;
    } else {
        template = (
            <>
                {
                    loading &&
                    <ReactLoading className="loading-time" type="spin" color="#143899" height={'80px'} width={'80px'} />
                }

                <Header toggleMenuSidebar={toggleMenuSidebar} />

                <MenuSidebar />

                <div className="content-wrapper">
                    <div className="pt-3" />
                    <section className="content">
                        <div className="container-fluid">
                            <Switch>
                                <Route exact path="/profile" component={Profile} />
                                /* General Payroll */
                                <Route exact path="/active-membership" component={ActiveMembership} />
                                <Route exact path="/general-arrival" component={GeneralArrival} />
                                <Route exact path="/personal" component={Personal} />
                                <Route exact path="/print-log" component={PrintLog} />
                                /* Processor */
                                <Route path="/graphic-production" component={GraphicProduction} />
                                <Route exact path="/production-agency" component={ProductionAgency} />
                                <Route exact path="/production-country" component={ProductionCountry} />
                                <Route exact path="/pr-production" component={PRProduction} />
                                <Route exact path="/unavailable-arrival" component={UnavailableArrival} />
                                <Route exact path="/contracts-time" component={ContractTime} />
                                /* Report By Sales Room */
                                <Route exact path="/adp-due-date-report" component={AdpDueDateReport} />
                                <Route exact path="/cancellation-report" component={CancellationReport} />
                                <Route exact path="/contract-analyst" component={ContractAnalyst} />
                                {/*<Route exact path="/cxc-report" component={CxcReport} />*/}
                                <Route exact path="/sales-room-stats" component={SalesRoomStats} />
                                <Route exact path="/gift-location-report" component={GiftLocationReport} />
                                <Route exact path="/gift-report" component={GiftReport} />
                                <Route exact path="/manifest-report" component={ManifestReport} />
                                <Route exact path="/membership-report" component={MembershipReport} />
                                <Route exact path="/pending-report" component={PendingReport} />
                                <Route exact path="/pre-manifest" component={PreManifest} />
                                <Route exact path="/sales-report" component={SalesReport} />
                                <Route exact path="/greeters-report" component={GreetersReport} />
                                /* Service innsist */
                                <Route exact path="/reservations-innsist" component={Reservations} />
                                <Route exact path="/countries-innsist" component={Countries} />
                                /* Dashboard Home */
                                <Route path="/" component={Dashboard} />
                            </Switch>
                        </div>
                    </section>
                </div>

                <Footer />
                <div
                    id="sidebar-overlay"
                    role="presentation"
                    onClick={toggleMenuSidebar}
                    onKeyDown={() => {}}
                />
            </>
        );
    }

    return (
        <div className="hold-transition sidebar-mini layout-fixed">
            <div className="wrapper">{template}</div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    onUserLoad: (user) =>
        dispatch({type: ActionTypes.LOAD_USER, currentUser: user})
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
