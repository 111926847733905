import React, {useEffect, useState} from 'react';
import { Dropdown } from 'semantic-ui-react'
import {finishLoading, startLoading} from "./../../store/actions/uiAction";
import HttpClient from "./../../services/httpClient";
import {useDispatch} from "react-redux";
import {setIdHotel, setIdSaleRoom} from "./../../store/actions/dataAction";

const ComboHotelSala = () => {
    const dispatch = useDispatch();
    const [salesRoomData, setSalesRoomData] = useState([]);
    const [salesRoom, setSalesRoom] = useState(0);
    const [hotelData, setHotelData] = useState([]);
    const [hotel, setHotel] = useState(0);

    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const getOptionData = (url) => {
        return new Promise((resolve, eject) => {
            getDataReport(url).then((response) => {
                if (response.status === 200) {
                    let obj = response.data;
                    let res = [];
                    for(let i in obj) {
                        let item = {key: obj[i].id, text: obj[i].name, value: obj[i].id};
                        res.push(item)
                    }

                    resolve(res);
                }
            })
        })
    }

    const getSalesRoomData = () => {
        getOptionData('/catalog/salasvta')
            .then((response) => {
                setSalesRoomData(response);
            })
    }

    const getHotelData = (idSalaVta) => {
        getOptionData(`/catalog/HotelByIdSalaVta/${idSalaVta}`)
            .then((response) => {
                setHotelData(response);
            })
    }

    const handleSalaVtaChange = (e, {value}) => {
        setSalesRoom(value);
        getHotelData(value);
        dispatch(setIdSaleRoom(value));
        dispatch(setIdHotel(0));
    }

    const handleHotelChange = (e, {value}) => {
        setHotel(value);
        dispatch(setIdHotel(value));
    }

    useEffect(() => {
        getSalesRoomData();
    }, []);

    return (
        <>
            <div className="row col-md-12">
                    <div className="form-group col-md-6">
                        <label>Sales room:</label>
                        <Dropdown className="form-control" placeholder='<< ALL SALES ROOM >>' selection
                                  options={salesRoomData}
                                  onChange={handleSalaVtaChange}
                                  value={salesRoom}
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <label>Hotel:</label>
                        <Dropdown className="form-control" placeholder='<< ALL HOTELS >>' selection clearable
                                  options={hotelData}
                                  onChange={handleHotelChange}
                                  value={hotel}
                        />
                    </div>


            </div>
        </>
    );
};

export default ComboHotelSala;
