import React, {useEffect, useState} from 'react';
import HttpClient from './../../../services/httpClient';
import { Dropdown } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {useTranslation} from "react-i18next";
import ReportViewer from "./../../../components/ReportViewer/ReportViewer";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {useDispatch, useSelector} from "react-redux";
import {logReportService} from "./../../../services/logReportService";
import {setTitle} from "./../../../store/actions/uiAction";
import ComboHotelSala from "./../../../components/combo-hotel-sala/combo-hotel-sala";

const UnavailableArrival = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [locationData, setLocationData] = useState([]);
    const [location, setLocation] = useState('');
    const [dateStart, setDateStart] = useState(new Date());
    const user = useSelector(state => state.auth.currentUser);
    const {idSaleRoom, idHotel} = useSelector(state => state.user.param);
    const onChangeDateStart = (event, data) => setDateStart(data.value);

    const getDataReport = (url) => {
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    eject(error);
                })
        });
    }

    const getLocationData = () => {
        getDataReport(`/catalog/locations`).then((response) => {
            if (response.status === 200) {
                let obj = response.data;
                let res = [];
                for(let i in obj) {
                    let item = {key: obj[i].id, text: obj[i].name, value: obj[i].id};
                    res.push(item)
                }
                setLocationData(res);
            }
        })
    }

    const handleLocationChange = ( e, {value}) => {
        setLocation( value );
    }

    const getData = (url) => {
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    eject(error);
                })
        });
    }

    const getReport = () => {
        const startDate = dateStart.toISOString().slice(0, 10).replace(/-/g,'');
        getData(`/reports/unavailable-arrival/${startDate}/${location}/${idSaleRoom}/${idHotel}`).then((response) => {
            if (response.status === 200) {
                //  setRowData(response.data);
                logReportService.add('Unavailable Arrival Report', user);
            }
        })
    }

    const handleViewButton = () => {
        getReport();
    }

    useEffect(() => {
        dispatch(setTitle(t('menusidebar.label.unavailable-arrival')));
        getLocationData();
    }, []);

    return (
        <div className="container-fluid">
            <ComboHotelSala />
            <div className="row pb-2">
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="col-md-12">Date start:</label>
                                <SemanticDatepicker className="col-md-12" name="dateStart" value={dateStart} onChange={onChangeDateStart} />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label className="col-md-12">Location:</label>
                                <Dropdown className="col-md-12" placeholder='Locations' selection
                                          options={locationData}
                                          onChange={handleLocationChange}
                                          value={location}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 pt-4">
                    <Button className="col-md-12" primary onClick={handleViewButton}>View Report</Button>
                </div>
            </div>
            <div>
                {/* <ReportViewer reportSource="unavailable-arrival.trdp"/> */}
            </div>
        </div>
    );
}

export default UnavailableArrival;
