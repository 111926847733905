import * as actionTypes from './../actions';

export const initialState = {
    data: null,
    param: {
        idSaleRoom: 0,
        idHotel: 0,
        idAgencia: 0, 
        idPais: 0,
        idUser:0
    }
}

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DATA_SUCCESS:
            return executeGetDataSuccess(state, action);
        case actionTypes.DATA_SET_SALE_ROOM:
            return  {
                ...state,
                param: {
                    ...state.param,
                    idSaleRoom: action.payload
                }
            }
        case actionTypes.DATA_SET_HOTEL:
            return {
                ...state,
                param: {
                    ...state.param,
                    idHotel: action.payload
                }
            }
        case actionTypes.DATA_SET_AGENCIA:
            return {
                ...state,
                param: {
                    ...state.param,
                    idAgencia: action.payload
                }
            }
            case actionTypes.DATA_SET_PAIS:
                return {
                    ...state,
                    param: {
                        ...state.param,
                        idPais: action.payload
                    }
                }
            case actionTypes.DATA_SET_USER:
                return {
                    ...state,
                    param: {
                        ...state.param,
                        idUser: action.payload
                    }
                }
        default:
            return state;
    }
}
export default dataReducer;

const executeGetDataSuccess = (state, action) => {
    return {
        ...state,
        data: action.data
    }
}